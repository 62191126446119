import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { AvatarGroup } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import KaleidoAvatar from 'common/components/KaleidoAvatar/KaleidoAvatar';
import { useArtCollectedBadgeStyles } from './ArtCollectedBadge.styles';

export type ArtCollectedPreviewChipProps = {
  seller: {
    avatar: string;
    title: string;
  };
  gallery?: {
    avatar: string;
    title: string;
  };
  avatarSize?: number;
};

const ArtCollectedInfoPreview: React.FC<ArtCollectedPreviewChipProps> = ({ seller, gallery, avatarSize = 48 }) => {
  const { t } = useTranslation();
  const classes = useArtCollectedBadgeStyles();

  return (
    <div className={classes.chipWrapper}>
      <AvatarGroup style={{ flexShrink: 0 }}>
        <KaleidoAvatar style={{ width: avatarSize, height: avatarSize }} src={seller.avatar} alt={seller.title} />
        {gallery && (
          <KaleidoAvatar style={{ width: avatarSize, height: avatarSize }} src={gallery?.avatar} alt={gallery?.title} />
        )}
      </AvatarGroup>
      <Box className={classes.sellerInfo}>
        <Typography variant="h5">{gallery ? gallery?.title : seller?.title}</Typography>
        <Typography variant="h6">
          {gallery ? t('showcase.provenanceTab.artGallery') : t('adminStrings.ghostProfile.button.artist')}
        </Typography>
      </Box>
    </div>
  );
};

export default ArtCollectedInfoPreview;
