import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { AvatarGroup } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import KaleidoAvatar from 'common/components/KaleidoAvatar/KaleidoAvatar';
import { useArtCollectedBadgeStyles } from './ArtCollectedBadge.styles';
import AnimatedArrow from './AnimatedArrow';

export type ArtCollectedChipProps = {
  seller: {
    avatar: string;
    title: string;
  };
  gallery?: {
    avatar: string;
    title: string;
  };
  buyer: {
    avatar: string;
    title: string;
  };
  isVisible?: boolean;
  avatarSize?: number;
};

const ArtCollectedInfo: React.FC<ArtCollectedChipProps> = ({ seller, gallery, buyer, isVisible, avatarSize = 48 }) => {
  const { t } = useTranslation();
  const classes = useArtCollectedBadgeStyles();

  return (
    <Box className={classes.chipWrapper}>
      <AvatarGroup style={{ flexShrink: 0 }}>
        <KaleidoAvatar style={{ width: avatarSize, height: avatarSize }} src={seller.avatar} />
        {gallery && <KaleidoAvatar style={{ width: avatarSize, height: avatarSize }} src={gallery?.avatar} />}
      </AvatarGroup>
      <Box className={classes.sellerInfo}>
        <Typography variant="h5">{gallery ? gallery?.title : seller?.title}</Typography>
        <Typography variant="h6">
          {gallery ? t('showcase.provenanceTab.artGallery') : t('adminStrings.ghostProfile.button.artist')}
        </Typography>
      </Box>
      <AnimatedArrow
        isVisible={isVisible}
        style={{
          flexShrink: 1,
          minWidth: '20px',
          width: '100%',
        }}
      />
      <KaleidoAvatar
        style={{ width: avatarSize, height: avatarSize, flexShrink: 0 }}
        containerClassName={clsx(classes.collectorAvatar, isVisible ? classes.collectorAvatarAnimation : '')}
        src={buyer.avatar}
      />
    </Box>
  );
};

export default ArtCollectedInfo;
