import { CDNOrigin, CDNUrl, ENV_TYPE } from 'utils/constants';

type ImageFormats = 'jpg' | 'jpeg' | 'png' | 'webp';

const supportedImageFormats: ImageFormats[] = ['jpg', 'jpeg', 'png', 'webp'];
export const isImageFormatSupported = (format?: string): format is ImageFormats =>
  !!format && supportedImageFormats.includes(format as ImageFormats);

type CDNImageSubTypeProps<
  SubTypeName extends string | number | symbol,
  SizesUnion extends string,
  ExtraProps extends unknown | Record<string, unknown>
> = Record<
  SubTypeName,
  {
    size: SizesUnion;
  } & ExtraProps
>;

type CDNImageTargetProps<
  ImageTargetName extends string,
  ImageTargetId extends number | string,
  ImageMedatadataId extends number,
  ImageTagetSubtypes extends CDNImageSubTypeProps<string, string, unknown>
> = Record<
  ImageTargetName,
  {
    id: ImageTargetId;
    fileid: ImageMedatadataId;
    subtypes: ImageTagetSubtypes;
  }
>;

type CDNArtShowImagesProps = CDNImageTargetProps<
  'ArtShow',
  string, // artshow.uri
  number,
  CDNImageSubTypeProps<'Banner', 'original', unknown>
>;

type CDNArtworkImagesProps = CDNImageTargetProps<
  'Artwork',
  number, // artwork.id
  number,
  CDNImageSubTypeProps<
    'Primary',
    | 'vuforia'
    | 'transfer-small'
    | 'transfer-large'
    | 'thumbnail-small'
    | 'thumbnail-medium'
    | 'thumbnail-large'
    | 'medium'
    | 'large'
    | 'blur'
    | 'original',
    unknown
  > &
    CDNImageSubTypeProps<'Thumbnail', 'large' | 'medium' | 'small', unknown> &
    CDNImageSubTypeProps<
      'Secondary',
      'xlarge' | 'large' | 'medium' | 'small',
      {
        imageId: number; // artwork_story_media.file_metadata_id
      }
    > &
    CDNImageSubTypeProps<
      'Story',
      'xlarge' | 'large' | 'medium' | 'small',
      {
        imageId: number; // artwork_story_media.file_metadata_id
      }
    >
>;

type CDNExhibitionImagesProps = CDNImageTargetProps<
  'Exhibitions',
  number, // collection.id
  number,
  CDNImageSubTypeProps<'Thumbnail', 'large' | 'medium' | 'small', unknown> &
    CDNImageSubTypeProps<'Banner', 'xlarge' | 'large' | 'medium' | 'small' | 'xsmall', unknown>
>;

type CDNArtPrizeImagesProps = CDNImageTargetProps<
  'ArtPrize',
  number, // artPrize.id
  number,
  CDNImageSubTypeProps<'Thumbnail', 'large' | 'medium' | 'small', unknown> &
    CDNImageSubTypeProps<'Background', 'xlarge' | 'large' | 'medium' | 'small' | 'xsmall', unknown>
>;

type CDNArtworkDetailsImagesProps = CDNImageTargetProps<
  'ArtworkDetails',
  number, // artworkDetails.id
  number,
  CDNImageSubTypeProps<'LocationMap', 'map', unknown>
>;

type CDNProfileImagesProps = CDNImageTargetProps<
  'Profile',
  number, // profile.id
  number,
  CDNImageSubTypeProps<'Avatar', 'xlarge' | 'large' | 'medium' | 'small' | 'xsmall', unknown> &
    CDNImageSubTypeProps<'Banner', 'xlarge' | 'large' | 'medium' | 'small' | 'xsmall', unknown> &
    CDNImageSubTypeProps<'Featured_art', 'xlarge' | 'large' | 'medium' | 'small' | 'xsmall', unknown> &
    CDNImageSubTypeProps<'KardBanner', 'xlarge' | 'large' | 'medium' | 'small' | 'xsmall', unknown> &
    CDNImageSubTypeProps<
      'Location',
      'map',
      { imageId: number } // To get profile_location_id path
    >
>;

type CDNImagesProps = CDNArtworkImagesProps &
  CDNProfileImagesProps &
  CDNExhibitionImagesProps &
  CDNArtPrizeImagesProps &
  CDNArtworkDetailsImagesProps &
  CDNArtShowImagesProps;

const isImageIdMeta = (meta: unknown): meta is { imageId: number } => !!(meta as { imageId: number })?.imageId;

const isImagSizeMeta = (meta: unknown): meta is { size: string } => !!(meta as { size: string })?.size;

const getMetaPath = <
  TargetType extends keyof CDNImagesProps,
  SubType extends keyof CDNImagesProps[TargetType]['subtypes']
>(
  type: TargetType,
  subType: SubType,
  meta: unknown
) => {
  const specialCase = `${type}_${String(subType)}`;

  if (!isImageIdMeta(meta)) {
    return '';
  }

  if (specialCase === 'Artwork_Story') {
    return `/Images/${meta.imageId}`;
  }

  if (specialCase === 'Artwork_Secondary') {
    return `/${meta.imageId}`;
  }

  if (type === 'Profile' && subType === 'Location') {
    return `/${meta.imageId}`;
  }

  return '';
};

const mockImages: { primary: Record<number, number>; thumbnail: Record<number, number> } = {
  primary: {
    // eslint-disable-next-line prettier/prettier
    11153: 22557,
    11162: 22561,
    11167: 22563,
    11170: 22564,
    11180: 22585,
    11183: 22586,
    11186: 22587,
    11189: 22588,
    11192: 22589,
    11195: 22590,
    11198: 22591,
    11201: 22592,
    11204: 22593,
    11207: 22594,
    11210: 22595,
    11213: 22596,
    11216: 22597,
    11219: 22598,
    11222: 22599,
    11225: 22600,
    11228: 22601,
    11231: 22602,
    11234: 22603,
    11237: 22604,
    11240: 22605,
    11243: 22606,
    11246: 22607,
    11249: 22608,
    11252: 22609,
    11255: 22610,
    11261: 22612,
    11264: 22613,
    11267: 22614,
    11270: 22615,
    11273: 22616,
    11276: 22617,
    11279: 22618,
    11282: 22619,
    11285: 22620,
    11288: 22621,
    11291: 22622,
    11294: 22623,
    11297: 22624,
    11300: 22625,
    11303: 22626,
    11306: 22627,
    11309: 22628,
    11312: 22629,
    11315: 22630,
    11318: 22631,
    11321: 22632,
    11324: 22633,
    11327: 22634,
    11330: 22635,
    11333: 22636,
    11336: 22637,
    11339: 22638,
    11342: 22639,
    11346: 22640,
    11349: 22641,
    11352: 22642,
    11355: 22643,
    11358: 22644,
    11361: 22645,
    11371: 22648,
    11374: 22649,
    11379: 22650,
    11385: 22652,
    11388: 22653,
    11391: 22654,
    11394: 22655,
    11397: 22656,
    11400: 22657,
    11403: 22658,
    11406: 22659,
    11409: 22660,
    11412: 22661,
    11415: 22662,
    11418: 22663,
    11421: 22664,
    11424: 22665,
    11427: 22666,
    11430: 22667,
    11433: 22668,
    11436: 22669,
    11439: 22670,
    11442: 22671,
    11445: 22672,
    11448: 22673,
    11451: 22674,
    11454: 22675,
    11457: 22676,
    11460: 22677,
    11463: 22678,
    11466: 22679,
    11469: 22680,
  },
  thumbnail: {
    // eslint-disable-next-line prettier/prettier
    11154: 22557,
    11163: 22561,
    11168: 22563,
    11171: 22564,
    11181: 22585,
    11184: 22586,
    11187: 22587,
    11190: 22588,
    11193: 22589,
    11196: 22590,
    11199: 22591,
    11202: 22592,
    11205: 22593,
    11208: 22594,
    11211: 22595,
    11214: 22596,
    11217: 22597,
    11220: 22598,
    11223: 22599,
    11226: 22600,
    11229: 22601,
    11232: 22602,
    11235: 22603,
    11238: 22604,
    11241: 22605,
    11244: 22606,
    11247: 22607,
    11250: 22608,
    11253: 22609,
    11256: 22610,
    11262: 22612,
    11265: 22613,
    11268: 22614,
    11271: 22615,
    11274: 22616,
    11277: 22617,
    11280: 22618,
    11283: 22619,
    11286: 22620,
    11289: 22621,
    11292: 22622,
    11295: 22623,
    11298: 22624,
    11301: 22625,
    11304: 22626,
    11307: 22627,
    11310: 22628,
    11313: 22629,
    11316: 22630,
    11319: 22631,
    11322: 22632,
    11325: 22633,
    11328: 22634,
    11331: 22635,
    11334: 22636,
    11337: 22637,
    11340: 22638,
    11343: 22639,
    11347: 22640,
    11350: 22641,
    11353: 22642,
    11356: 22643,
    11359: 22644,
    11362: 22645,
    11372: 22648,
    11375: 22649,
    11380: 22650,
    11386: 22652,
    11389: 22653,
    11392: 22654,
    11395: 22655,
    11398: 22656,
    11401: 22657,
    11404: 22658,
    11407: 22659,
    11410: 22660,
    11413: 22661,
    11416: 22662,
    11419: 22663,
    11422: 22664,
    11425: 22665,
    11428: 22666,
    11431: 22667,
    11434: 22668,
    11437: 22669,
    11440: 22670,
    11443: 22671,
    11446: 22672,
    11449: 22673,
    11452: 22674,
    11455: 22675,
    11458: 22676,
    11461: 22677,
    11464: 22678,
    11467: 22679,
    11479: 22680,
  },
};
const shouldUseMockImages = ENV_TYPE !== 'production';

export const randomWithSeed = (seed: number) => {
  // in case we want to still use other calculation (% gives more like a sequence than a fake random)
  // const x = Math.ceil(Math.abs(Math.sin(seed) * 10))
  const x = (seed % 10) + 1;
  const number = x.toString().padStart(2, '0');
  return number;
};

// taken from here :https://developers.google.com/speed/webp/faq#how_can_i_detect_browser_support_for_webp
// check_webp_feature:
//   'feature' can be one of 'lossy', 'lossless', 'alpha' or 'animation'.
//   'callback(feature, isSupported)' will be passed back the detection result (in an asynchronous way!)
function check_webp_feature(
  feature: 'lossy' | 'lossless' | 'alpha' | 'animation',
  callback: (feature: 'lossy' | 'lossless' | 'alpha' | 'animation', result: boolean) => void
) {
  const kTestImages = {
    lossy: 'UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA',
    lossless: 'UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==',
    alpha:
      'UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==',
    animation:
      'UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA',
  };
  const img = new Image();
  img.onload = function () {
    const result = img.width > 0 && img.height > 0;
    callback(feature, result);
  };
  img.onerror = function () {
    callback(feature, false);
  };
  img.src = `data:image/webp;base64,${kTestImages[feature]}`;
}

let defaultImgFormat: ImageFormats = 'webp';
check_webp_feature('lossy', (_, isWebpSupported) => {
  defaultImgFormat = isWebpSupported ? 'webp' : 'jpg';
});

export const getCDNImageUrl = <
  TargetType extends keyof CDNImagesProps,
  SubType extends keyof CDNImagesProps[TargetType]['subtypes'],
  SubTarget extends CDNImagesProps[TargetType]['subtypes'][SubType] = CDNImagesProps[TargetType]['subtypes'][SubType]
>({
  target,
  targetId,
  fileId,
  purpose,
  meta,
  format = defaultImgFormat,
  skipDefaultProfileImage = false,
}: {
  target: TargetType;
  targetId: CDNImagesProps[TargetType]['id'];
  fileId: CDNImagesProps[TargetType]['fileid'];
  purpose: SubType;
  meta: SubTarget;
  format?: ImageFormats;
  skipDefaultProfileImage?: boolean;
}): string => {
  let actualTargetId = targetId;
  if (shouldUseMockImages) {
    const possibleReplacement = mockImages.primary[Number(fileId)] ?? mockImages.thumbnail[Number(fileId)];
    actualTargetId = possibleReplacement ?? actualTargetId;
  }
  if (!skipDefaultProfileImage && target === 'Profile' && purpose === 'Avatar' && !fileId) {
    return `${CDNUrl}/images/profilePictures/ProfilePicture${randomWithSeed(Number(targetId))}.svg`;
  }
  const base = `${CDNOrigin}/${ENV_TYPE === 'production' ? 'CDN' : 'CDN/staging'}/${target}/${actualTargetId}/${String(
    purpose
  )}`;
  const optionalMetaPath = getMetaPath(target, purpose, meta);
  const file = isImagSizeMeta(meta) && `/${meta.size}.${format}?updated=${fileId}`;
  return `${base}${optionalMetaPath}${file}`;
};

/*
Useage Example:
geCDNImageUrl({
  target: 'Artwork' | 'Thumbnail',
  targetId: 12,
  fileId: 14,
});

geCDNImageUrl({
  target: 'Artwork',
  purpose: 'Secondary',
  targetId: 12,
  fileId: 14,
  meta: {
    size: 'large',
    imageId: 12,
  },
});
*/
