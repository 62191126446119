import { CurrencyTypeEnum } from 'common/components/CurrencySelector/CurrencySelector.formik';
import { LocationType } from 'common/features/GeoLocation/geolocationUtils';
import { GetShowcaseDetailsByHandleAndSlugQuery } from 'common/schema/commonSchemaRemoteOperationTypes';
import {
  Enum_Artwork_Edition_Type_Enum,
  Enum_Blockchain,
  Enum_Position_Type_Enum,
  Enum_Size_Enum,
} from 'common/schema/commonSchemaRemoteTypes';
import { ArtworkProvenanceProps } from 'stacks/ProfileArtShowcase/components/ProfileArtShowcaseLeftSide/components/ProfileArtShowcaseSidebar/components/ArtworkProvenance/ArtworkProvenance';
import { ProvenanceTabProviderProps } from 'stacks/ShowcaseNewV2/components/DetailsTab/components/ProvenanceTab/context/ProvenancePickerProvider';
import { Profile } from '../../common/types';

export type ShowcaseDetailsQueryType = GetShowcaseDetailsByHandleAndSlugQuery['Artwork'][number];
export type TransactionHistoriesType =
  GetShowcaseDetailsByHandleAndSlugQuery['Artwork'][number]['transaction_histories'];
export type SecondaryTagsType = GetShowcaseDetailsByHandleAndSlugQuery['Artwork'][number]['secondary_tags'];
export type CollectorContextType =
  GetShowcaseDetailsByHandleAndSlugQuery['Artwork'][number]['transaction_histories'][number]['buyer_context'];
export type GalleryContextType = GetShowcaseDetailsByHandleAndSlugQuery['Artwork'][number]['gallery_context'];
export type ArtworkToCollectionType =
  GetShowcaseDetailsByHandleAndSlugQuery['Artwork'][number]['artwork_to_collections'][number]['Collection']['artwork_to_collections'][number];
export type SeriesArtworkType =
  GetShowcaseDetailsByHandleAndSlugQuery['Artwork'][number]['artwork_to_collections'][number]['Collection']['artwork_to_collections'][number]['Artwork'];

export type Chip = {
  avatar: string;
  title: string;
  subtitle: string;
};

export type Collector = Profile & {
  isPublic: boolean;
};
export type Gallery = Profile;

export type ArtworkDimensions = {
  width: number;
  height: number;
  depth: number;
  measuringUnit: string;
};

export type PrintDimensions = Omit<ArtworkDimensions, 'depth'>;

type ArtworkDetails = {
  dimensions: ArtworkDimensions;
  creationDate: {
    day?: number;
    month?: number;
    year?: number;
  };
  medium: string;
  discipline: string;
  blockchain?: string;
  blockchain_enum?: Enum_Blockchain;
  blockchain_other: string;
  nft_url: string;
  nft_copies?: number;
  art_type: string;
  hasAR: boolean;
  vuforiaRating?: number;
  vuforiaTargetId?: string;
  pendingVuforiaImagePath?: string;
};

export type ShowcaseArtworkTab = {
  detailsId: number;
  hasCollector: boolean;
  collector?: Collector;
  hasPartnerGallery: boolean;
  gallery?: Gallery;
  primaryTag: {
    name: string;
    description: string;
  };
  secondaryTags: string[];
  location?: LocationType;
  locationMapId?: number;
  locationAddress?: string;
  details: ArtworkDetails;
  artworkStatement: string;
  artworkTitle: string;
  seriesStatement: string;
  seriesName: string;
  seriesArtworks: SeriesArtworkType[];
};

type ShowcaseArtistTab = {
  artistStatement: string;
  artistName: string;
  artistLocation: string;
  artistImage: string;
};

export type ShowcaseEditionDetails = {
  id: number;
  currency?: CurrencyTypeEnum;
  fixedPrice?: number;
  isPricePublic?: boolean;
  startingPrice?: number;
  technique: string;
  material?: string;
  editionByTypes: ({
    name: string;
    printsAmount: number;
  } & Pick<ShowcaseDetailsQueryType['editions'][0]['Edition_By_Sizes'][0]['Edition_By_Types'][0], 'id'>)[];
  createdOn: {
    year: number;
    month?: number;
    day?: number;
  };
} & PrintDimensions &
  Pick<ShowcaseDetailsQueryType['editions'][0], 'isForSale'>;

export type ShowcaseEditionsTab = {
  type: Enum_Artwork_Edition_Type_Enum | null;
  editionSizesDetails: { [size in Enum_Size_Enum]?: ShowcaseEditionDetails };
};

export type ShowcaseProvenanceTab = ArtworkProvenanceProps & Partial<Pick<ProvenanceTabProviderProps, 'editionSizes'>>;

export type ArtPrizeType = {
  place: Enum_Position_Type_Enum;
  artPrizeTitle: string;
  prizeSlug: string;
};
export type ShowcaseDetailsType = {
  artworkTab: ShowcaseArtworkTab;
  artistTab: ShowcaseArtistTab;
  editionsTab: ShowcaseEditionsTab;
  provenanceTab: ShowcaseProvenanceTab;
  artPrizes: ArtPrizeType[];
  bestArtPrize?: ArtPrizeType;
};

export const defaultShowcaseDetails: ShowcaseDetailsType = {
  artworkTab: {
    detailsId: 0,
    artworkStatement: '',
    artworkTitle: '',
    seriesName: '',
    seriesArtworks: [],
    details: {
      hasAR: false,
      creationDate: {
        day: undefined,
        month: undefined,
        year: undefined,
      },
      dimensions: {
        width: 0,
        height: 0,
        depth: 0,
        measuringUnit: '',
      },
      discipline: '',
      medium: '',
      blockchain: undefined,
      blockchain_enum: undefined,
      blockchain_other: '',
      nft_url: '',
      nft_copies: 0,
      art_type: '',
    },
    hasCollector: false,
    hasPartnerGallery: false,
    seriesStatement: '',
    primaryTag: {
      name: '',
      description: '',
    },
    secondaryTags: [],
    location: undefined,
    locationAddress: undefined,
    locationMapId: undefined,
    collector: undefined,
    gallery: undefined,
  },
  artistTab: {
    artistStatement: '',
    artistName: '',
    artistLocation: '',
    artistImage: '',
  },
  editionsTab: {
    type: null,
    editionSizesDetails: {},
  },
  provenanceTab: {
    provenanceData: [],
  },
  artPrizes: [],
  bestArtPrize: undefined,
};
