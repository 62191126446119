import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DocumentsReadyQueueType } from 'stacks/MyDocuments/utils/myDocumentsTypes';

export interface generateNewCertificateModalState {
  currentPage: number;
  itemsPerPage: number;
  totalDocuments: number;
  hasDocuments: boolean;
  myDocumentsReadyQueue: DocumentsReadyQueueType[];
}

const initialState: generateNewCertificateModalState = {
  currentPage: 1,
  itemsPerPage: 20,
  totalDocuments: 0,
  hasDocuments: false,
  myDocumentsReadyQueue: [],
};

const myDocumentsSlice = createSlice({
  name: 'myDocuments',
  initialState,
  reducers: {
    SET_ITEMS_PER_PAGE: (state, action: PayloadAction<number>) => ({ ...state, itemsPerPage: action.payload }),
    SET_CURRENT_PAGE: (state, action: PayloadAction<number>) => ({ ...state, currentPage: action.payload }),
    SET_TOTAL_DOCUMENTS: (state, action: PayloadAction<number>) => ({ ...state, totalDocuments: action.payload }),
    SET_HAS_DOCUMENTS: (state, action: PayloadAction<boolean>) => ({ ...state, hasDocuments: action.payload }),
    SET_DOCUMENTS_READY_QUEUE: (state, action: PayloadAction<DocumentsReadyQueueType>) => ({
      ...state,
      myDocumentsReadyQueue: [...state.myDocumentsReadyQueue, action.payload],
    }),
    REMOVE_DOCUMENTS_FROM_READY_QUEUE: (state, action: PayloadAction<number[]>) => ({
      ...state,
      myDocumentsReadyQueue: state.myDocumentsReadyQueue.filter(
        (document) => !action.payload.includes(document.pieceId)
      ),
    }),
  },
});

export const myDocumentsActions = myDocumentsSlice.actions;
export default myDocumentsSlice;
