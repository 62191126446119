import { Theme, useMediaQuery } from '@material-ui/core';
import { TABLET_BREAKPOINT } from 'common/utils/constants';
import { useCurrentWindowSize } from 'common/utils/windowSize';
import { isMobile } from 'react-device-detect';
/**
 * * Returns true if the screen size is small enough to be considered mobile
 * * or if the device if a mobile device
 */
export const useIsMobileScreen = (): boolean => {
  const isMobileWindowSize = useCurrentWindowSize().down(TABLET_BREAKPOINT);
  const isMobileMediaQuery = useMediaQuery((theme: Theme) => theme.breakpoints.down(TABLET_BREAKPOINT), {
    noSsr: true,
  });
  return isMobileWindowSize || isMobileMediaQuery || isMobile;
};
