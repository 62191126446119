import _, { isNaN } from 'lodash';
import { socialMediaEnum } from '@old/shared/SocialMediaInput/socialMediaEnum';
import { SOCIAL_MEDIA_DATA } from '@old/shared/SocialMediaInput/utils';
import {
  Artwork,
  Artwork_Story,
  Artwork_Story_Media,
  Context,
  File_Metadata,
  Maybe,
  Profile,
  Profile_Location,
  Enum_Profile_Public_Status_Enum,
  Artwork_Details,
  Enum_Transaction_Status_Enum,
  Edition,
  Profile_Price_Ranges,
  Profile_Settings,
  Enum_Language_Preference_Enum,
  Profile_Phones,
  Enum_Collection_Type_Enum,
  Enum_My_Website_Theme_Enum,
  Enum_Context_Type_Enum,
} from 'common/schema/commonSchemaRemoteTypes';
// todo : change this global t to local one
import { t } from 'i18next';
import * as mime from 'mime-types';
import { v5 as uuidv5 } from 'uuid';
import { ManageArtworkTransferStateType } from 'stacks/Dashboard/common/ArtworkManager/context/utils';
import { DashboardRoutesConfig } from 'stacks/Dashboard/common/DashboardRoot/configs/routesConfigs';
import { ProfileType, SelectedProfile } from 'common/hooks/useSelectedProfile';
import { logger } from '@old/services/logger';
import { getCountryFullName } from 'common/hooks/useCountries';
import { TransferPrintData } from 'stacks/Dashboard/common/EditionPrintsFilter/EditionPrintsFilter';
import { generatePath } from 'react-router';
import { TransferPrintParams } from 'stacks/Dashboard/common/ArtworkTransfer/utils/typeGuards';
import { CurrencyTypeEnum } from 'common/components/CurrencySelector/CurrencySelector.formik';
import { DefaultEditionType, PrintPriceType } from 'common/features/FormikConfigs/components/Prints';
import { ArtistContextFragment } from 'common/schema/commonSchemaRemoteOperationTypes';
import { BrowserLocale } from 'common/hooks/useLanguage/types';
import { DEFAULT_ARTWORK_TITLE, DEFAULT_PROFILE_PICTURE_NAME } from 'common/utils/constants';
import { ArtworkMeasureUnit } from 'common/utils/types';
import { CDNOrigin, ENV_TYPE, IS_CUSTOM_DOMAINS_APP, MINIMUM_VUFORIA_RATING, URL_LINK } from './constants';
import { getCDNImageUrl } from './CDNImages';
import { AudioGenerationSource, getCDNAudioUrl } from './CDNAudio';

export const changeElementFocus = (): void => {
  document.getElementById('refocus')?.focus();
};

export const maybeFileUrl = (file?: string | File): null | string => {
  if (!file) {
    return null;
  }
  if (file instanceof File) {
    return URL.createObjectURL(file);
  }
  return file;
};
export const hasNumber = (str: string) => {
  const regex = /\d/;
  return regex.test(str);
};

export const capitalize = (str: string) => {
  if (!str) {
    return '';
  }
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const imageToBlob = (image: File | string) => {
  if (image instanceof File) {
    return URL.createObjectURL(image);
  }
  return image;
};

export const mapSocialProfiles = (profilesArray: any[] = []): { [k: string]: string } => {
  const result: any = {};
  profilesArray.forEach((x) => {
    result[x.platform] = x.URL;
  });
  return result;
};

export type UserProfile = {
  contextId: Context['id'];
  profileId: Profile['id'];
  firstName: Profile['first_name'];
  lastName: Profile['last_name'];
  displayType: Profile['is_public'];
  onboardingStep?: number;
  profilePhoto: Profile['avatar_download_link'];
  profileTheme?: Profile['profile_theme'];
  locations: Array<Pick<Profile_Location, 'name' | 'country' | 'city' | 'address' | 'zip_code' | 'region'>>;
  settings: Pick<Profile_Settings, 'id' | 'preferred_language' | 'currency' | 'crop_image'>;
  phones: Array<Pick<Profile_Phones, 'phone'>>;
  interestedPriceRanges: Array<Profile_Price_Ranges['id']>;
  socialLinks: { [key in socialMediaEnum]: string };
  description?: string;
} & Pick<Context, 'state' | 'type'> &
  Pick<Profile, 'handle' | 'has_used_card' | 'title' | 'statement' | 'quote' | 'kard_banner' | 'email'>;

export const lastday = (y: number, m: number) => new Date(y, m + 1, 0).getDate();

export const blobTofile = async (blobInput: string, isKaleidoDefault?: boolean): Promise<File> => {
  const blob = await fetch(blobInput).then((r) => r.blob());
  const extensionName = mime.extension(blob.type);
  const extension = extensionName ? `.${extensionName}` : '';
  const fileName = isKaleidoDefault ? `${DEFAULT_PROFILE_PICTURE_NAME}${extension}` : `file${extension}`;
  return new File([blob], fileName, { type: blob.type });
};

export const isBlob = (value?: string): boolean => value?.split(':')[0] === 'blob';

export const fileToUploadOrNull = async (file: File | string): Promise<Maybe<File>> => {
  if (!file) {
    return null;
  }

  if (file instanceof File) {
    return file;
  }

  if (!file.includes(window.location.host)) {
    return null;
  }

  return await blobTofile(file);
};

export const handleConversionInchtoCm = (inch: any) => parseFloat((inch * 2.54).toFixed(2));

export const handleConversionCmtoInch = (cm: any) => parseFloat((cm * 0.3937007).toFixed(2));

export const validateEmail = (email: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const mod = (n: number, m: number): number => ((n % m) + m) % m;

export const parseDate = (date: string): string => {
  if (!date) {
    return '';
  }

  const date_var = new Date(date);
  const month_lowerCase = date_var.toLocaleString('en-us', { month: 'long' }).toLocaleLowerCase();
  const month = `${month_lowerCase[0].toUpperCase()}${month_lowerCase.slice(1)}`;
  const day = date_var.getDate();
  const year = date_var.getFullYear();

  if (month && day && year) {
    return `${day} ${month} ${year}`;
  }

  return '';
};

export const getMonth = (month: number): string => {
  if (!Array.from(Array(12).keys()).includes(month)) {
    return '';
  }
  return t(`dateStrings.monthNames.${month}`);
};

export const capitalizeFirstLetter = (str: string): string => {
  if (!str) {
    return '';
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export function copyToClipboard(text: string): void {
  const dummy = document.createElement('textarea');
  document.body.appendChild(dummy);
  dummy.value = text;
  dummy.select();
  document.execCommand('copy');
  document.body.removeChild(dummy);
  navigator.clipboard.writeText(text);
}

export function debounce<T extends (...args: U) => void, U extends unknown[] = Parameters<T>>(
  func: T,
  timeout = 500
): (...args: U) => void {
  let timer: NodeJS.Timeout | null = null;
  return (...args: U) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout((): void => {
      func(...args);
    }, timeout);
  };
}

export const goUpRouterLevel = (path: string, levels = 1): string => {
  let mutablePath = path;
  for (let i = 0; i < levels; i++) {
    mutablePath = mutablePath.substring(0, mutablePath.lastIndexOf('/'));
  }

  return mutablePath;
};

export const getArtworkShowcaseUrl = (
  artwork: any,
  profileHandle: string | null = null,
  id: number | null = null
): string => {
  const linkedArtworkId = artwork.id || id;
  const linkedHandle = artwork?.artist_context?.profile?.handle || profileHandle;

  return `${linkedHandle}/showcase/${linkedArtworkId}`;
};

export const getArtworkShowcaseUrlSlug = (
  artwork?:
    | (Pick<Artwork, 'slug'> & {
        id?: number | null;
        artist_context?: {
          profile?: Maybe<Pick<Profile, 'handle'>>;
        } | null;
      } & {
        handle?: string;
      })
    | null,
  handle: string | null = null,
  slug: string | null = null
): string => {
  let artworkSlug: string | null = null;
  let profileHandle: string | null = null;

  if (artwork?.slug) {
    artworkSlug = artwork.slug;
  } else if (slug) {
    artworkSlug = slug;
  } else if (artwork?.id) {
    artworkSlug = artwork.id.toString();
  }

  if (artwork?.artist_context?.profile?.handle) {
    profileHandle = artwork.artist_context.profile.handle;
  } else if (handle) {
    profileHandle = handle;
  } else if (artwork?.handle) {
    profileHandle = artwork.handle;
  }

  const showcaseUrl: string = [!IS_CUSTOM_DOMAINS_APP ? `/${profileHandle}` : '', 'showcase', artworkSlug].join('/');

  return showcaseUrl;
};

export const textSlugify = (...args: (string | number)[]): string => {
  const value = args.join(' ');

  return value
    .normalize('NFD') // split an accented letter in the base letter and the acent
    .replace(/[\u0300-\u036f]/g, '') // remove all previously split accents
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9 ]/g, '') // remove all chars not letters, numbers and spaces (to be replaced)
    .replace(/\s+/g, '-'); // separator
};

export const getArtworkTitleSlug = (artwork: any) => {
  if (!artwork.id || !artwork.title) {
    return null;
  }
  return textSlugify(artwork.title);
};

/**
 * @deprecated This logic was moved on BE. A trigger on artwork details title updates the artwork slug.
 * @param args
 * @returns
 */
export const textSlugifyWithTrim = (...args: (string | number)[]): string => {
  const slugText = textSlugify(...args);

  const splitText = slugText.split('-');

  return splitText.length > 2 ? splitText.slice(0, 3).join('-') : slugText;
};

type MaybeFileMetadataExtra = Maybe<Pick<File_Metadata, 'extra'>>;

export const getArtworkTitle = (
  artwork?: { artwork_details?: Pick<Artwork_Details, 'title'> | null } | null
): string => {
  const title = artwork?.artwork_details?.title;
  if (!title?.length || title === DEFAULT_ARTWORK_TITLE) {
    return '';
  }
  return title;
};

export const getArtworkStoryAudioLink = (
  artwork_story?: Maybe<Pick<Artwork_Story, 'story_audio_download_link'> & { audio_metadata?: MaybeFileMetadataExtra }>,
  type = 'large'
): string => {
  if (artwork_story?.audio_metadata?.extra) {
    const extra = JSON.parse(artwork_story.audio_metadata.extra);

    if (extra[type]) {
      return `https://cdn.kaleido.art/${extra[type]}`; // `${CDNBaseUrl}/`
    }
  }
  return artwork_story?.story_audio_download_link || '';
};

export const getArtworkStoryMediaAudioLink = (
  artwork_story_media: Pick<Artwork_Story_Media, 'artwork_story_media_download_link'> & {
    artwork_story_media_metadata?: MaybeFileMetadataExtra;
  },
  type = 'mp3_storage_path'
): string => {
  if (artwork_story_media?.artwork_story_media_metadata?.extra) {
    const extra = JSON.parse(artwork_story_media.artwork_story_media_metadata.extra);

    if (extra[type]) {
      return `https://cdn.kaleido.art/${extra[type]}`; // `${CDNBaseUrl}/`
    }
  }

  return artwork_story_media?.artwork_story_media_download_link || '';
};

export const getCollectionAudioLink = (collection: any, type = 'mp3_storage_path'): string => {
  if (collection?.audio_metadata?.extra) {
    try {
      const extra = JSON.parse(collection?.audio_metadata?.extra);

      if (extra[type]) {
        return `https://cdn.kaleido.art/${extra[type]}`; // `${CDNBaseUrl}/`
      }
    } catch (ignored) {}
  }

  return collection?.collection_audio_download_link || '';
};

export const isSomeEnum =
  <T,>(e: T) =>
  (token: unknown): token is T[keyof T] => {
    if (token === null || token === undefined) {
      return false;
    }
    if (typeof e !== 'object') {
      return false; // TODO throw if not an object
    }
    return Object.values(e as any).includes(token as T[keyof T]);
  };

export const guardStringEnum =
  <T,>(e: T) =>
  (token: string): T[keyof T] => {
    if (!isSomeEnum(e)(token)) {
      throw new Error(`${token} is not enum ${e}`);
    }
    return token as any;
  };

export const getProfileAudioLink = (
  profile?:
    | null
    | (Pick<Profile, 'audio_download_link' | 'Valid_profile_statement_audio_syntheses'> & {
        audio_metadata?: null | Pick<File_Metadata, 'extra'>;
      } & {
        // TODO : deal with this typing after renaming all the aliases away from Profile_statement_audio_syntheses
        Valid_profile_statement_audio_syntheses: Profile['Valid_profile_statement_audio_syntheses'];
      }),
  languageToGet: Enum_Language_Preference_Enum = Enum_Language_Preference_Enum.English,
  type = 'mp3_storage_path'
): string => {
  if (!profile) {
    return '';
  }

  // get AI generated Audio for profile for the specified language. If not found get the english one
  const generatedAudio =
    profile.Valid_profile_statement_audio_syntheses?.find((synthesis) => synthesis.language === languageToGet) ||
    profile.Valid_profile_statement_audio_syntheses?.find(
      (synthesis) => synthesis.language === Enum_Language_Preference_Enum.English
    );

  if (generatedAudio && generatedAudio.language && generatedAudio.profile_id) {
    return getCDNAudioUrl({
      target: 'Profile',
      targetId: generatedAudio.profile_id,
      purpose: 'Statement',
      fileId: generatedAudio.audio_id ?? 0,
      meta: {
        language: guardStringEnum(Enum_Language_Preference_Enum)(generatedAudio.language),
        source: AudioGenerationSource.AI_GENERATED,
      },
      format: 'mp3',
    });
  }

  const { audio_metadata, audio_download_link } = profile;

  if (audio_metadata?.extra) {
    try {
      const { [type]: extra } = JSON.parse(audio_metadata.extra);

      if (extra) {
        return `${CDNOrigin}/${extra}`; // `${CDNBaseUrl}/`
      }
    } catch (err) {
      console.log('BAD FORMAT AUDIO METADATA EXTRA', err);
    }
  }

  return audio_download_link || '';
};

export const isProfilePublic = (profile: Partial<Profile> | undefined | null): boolean => {
  if (profile && profile.is_public !== Enum_Profile_Public_Status_Enum.Private) {
    return true;
  }
  return false;
};

export const getPreferredName = (profile: Partial<Profile> | undefined | null): string => {
  if (!isProfilePublic(profile)) {
    return t('myCollectorsStrings.private');
  }
  if (profile?.is_public?.includes('LEGAL_NAME')) {
    return `${profile?.first_name} ${profile?.last_name}`;
  }
  return profile?.handle ?? '';
};

export const getArtworkProfileTitle = (artwork: any): string => {
  if (artwork && artwork.artist_context && artwork.artist_context.profile) {
    if (artwork.artist_context.profile.first_name && artwork.artist_context.profile.last_name) {
      return `${artwork.artist_context.profile.first_name}  ${artwork.artist_context.profile.last_name}`;
    }
    if (artwork.artist_context.profile.title) {
      return artwork.artist_context.profile.title;
    }
  }
  return '';
};

export const contextToUserProfile = (context: Context): UserProfile => ({
  type: context.type,
  contextId: context.id,
  state: context?.state,
  profileId: context.profile?.id ?? 0,
  firstName: context.profile?.first_name,
  lastName: context.profile?.last_name,
  handle: context.profile?.handle,
  has_used_card: context.profile?.has_used_card || false,
  title: context.profile?.title,
  description: context.profile?.description ?? '',
  onboardingStep: context.latest_setup_step,
  displayType: context.profile?.is_public,
  profilePhoto: getCDNImageUrl({
    target: 'Profile',
    purpose: 'Avatar',
    targetId: context?.profile?.id ?? 0,
    fileId: context?.profile?.avatar ?? 0,
    meta: {
      size: 'xlarge',
    },
  }),
  kard_banner: context.profile?.kard_banner ?? 0,
  profileTheme: context.profile?.profile_theme,
  locations: context.locations.length
    ? context.locations
    : [
        {
          name: null,
          country: null,
          city: null,
          address: null,
          zip_code: null,
        },
      ],
  settings: context.profile_settings || {
    id: 0,
    preferred_language: Enum_Language_Preference_Enum.English,
    currency: null,
    crop_image: null,
  },
  phones: context.profile_phones?.length ? context.profile_phones : [],
  statement: context.profile?.statement,
  quote: context.profile?.quote,
  interestedPriceRanges: context.profile_selected_price_ranges?.map((range) => range.price_range_id),
  socialLinks: mapSocialProfiles(context.profile_social_links) as { [key in socialMediaEnum]: string },
  email: context.profile?.email,
});

export const processURL = (url: string, platform?: socialMediaEnum | string) => {
  if (url.length === 0) {
    return url;
  }
  const [, possibleProtocolSplit] = url.split('://');
  const urlWithoutProtocol = possibleProtocolSplit || url;

  if (platform && isSomeEnum(socialMediaEnum)(platform)) {
    // console.log(">>> hel");

    const prefix = SOCIAL_MEDIA_DATA[platform]?.prefix;
    if (prefix.length) {
      return prefix + urlWithoutProtocol;
    }
  }

  const regex = /^(((https|http|ftp):)\/\/)/;
  if (regex.test(urlWithoutProtocol) === false) {
    return `https://${urlWithoutProtocol}`;
  }
  return urlWithoutProtocol;
};

export const getTextWidth = (text?: string, font?: string): number => {
  if (!text?.length) {
    return 0;
  }
  const extra = text.trim().split(/\s+/).length > 1 ? 16 : 8;
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  if (!context) {
    return 0;
  }
  context.font = font || getComputedStyle(document.body).font;
  return context.measureText(text).width + extra;
};

export const getCookie = (cname: string): string => {
  const name = `${cname}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export const setCookie = (name: string, value: string, days?: number) => {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${value || ''}${expires}; path=/`;
};

export const ordinalize = (n: number): string => n + (['st', 'nd', 'rd'][((((n + 90) % 100) - 10) % 10) - 1] || 'th');

export const isCdnUrl = (value: string | File): boolean => typeof value === 'string' && value.includes(CDNOrigin);

export const isStorageUrl = (value: string | File): boolean =>
  typeof value === 'string' && value.includes('kaleido-marketplace-files');

export const isAcceptableVuforiaRating = (artworkRating?: number | Pick<Artwork, 'vuforia_rating'> | null): boolean => {
  if (!artworkRating) {
    return false;
  }
  let rating = 0;
  if (typeof artworkRating === 'number') {
    rating = artworkRating;
  } else if (artworkRating.vuforia_rating) {
    rating = artworkRating.vuforia_rating;
  }
  return rating >= MINIMUM_VUFORIA_RATING;
};

export const generateFileUuid = (file: File) => {
  const seed = `${file.name}_${file.size}_${file.lastModified}`.replaceAll(' ', '');
  return uuidv5(seed, uuidv5.URL);
};

export const getContextProfileTitle = (context: ArtistContextFragment): string => {
  if (context.profile?.title) {
    return context.profile.title;
  }

  if (context.profile?.first_name && context.profile.last_name) {
    return [context.profile?.first_name, context.profile?.last_name].join(' ');
  }

  return t('collectorProfileStrings.profileViewPage.unknown');
};

export const getContextLocation = <
  T extends {
    locations?: {
      city?: string | null;
      country?: string | null;
    }[];
  }
>(
  context?: T | null
): string => {
  if (context?.locations?.length === 0) {
    return '';
  }
  if (context && context.locations && context?.locations[0]) {
    return [context.locations[0]?.city, getCountryFullName(context.locations[0]?.country || '')]
      .filter((name) => !!name)
      .join(', ');
  }
  return '';
};

export const getProfileFullName = (profile?: Pick<Profile, 'first_name' | 'last_name' | 'title'> | null): string => {
  if (profile) {
    if (profile.title) {
      return profile.title;
    }
    if (profile.first_name || profile.last_name) {
      return `${profile.first_name} ${profile.last_name}`;
    }
  }
  return '';
};

export type GetProfileNameType = {
  handle?: string | null;
  title?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  isPublic?: string | null;
  profileType?: string | null;
};

export const getProfileName = (profile: GetProfileNameType): string => {
  if (profile.isPublic === 'PUBLIC_USERNAME' && profile.handle) {
    return profile.handle;
  }

  if (profile.title && profile.profileType !== 'COLLECTOR') {
    return profile.title;
  }

  if (profile.firstName || profile.lastName) {
    return [profile.firstName, profile.lastName].join(' ');
  }
  return t('myCollectorsStrings.private');
};

export const getContextAvatar = <
  T extends {
    profile?: null | {
      id?: number | null;
      avatar?: number | null;
    };
  }
>(
  context: T
): string =>
  getCDNImageUrl({
    target: 'Profile',
    purpose: 'Avatar',
    targetId: context.profile?.id ?? 0,
    fileId: context.profile?.avatar ?? 0,
    meta: {
      size: 'large',
    },
  });

export const getProfileTitle = <
  T extends {
    title?: string | null;
    first_name?: string | null;
    last_name?: string | null;
  }
>(
  profile?: T | null
): string => {
  const unknown = t('collectorProfileStrings.profileViewPage.unknown');

  if (profile?.title) {
    return profile.title;
  }

  if (profile?.first_name && profile?.last_name) {
    return [profile.first_name, profile.last_name].join(' ');
  }

  return unknown;
};

export const getSelectedProfileTitle = (profile: Maybe<SelectedProfile>): string => {
  if (!profile) {
    return '';
  }

  if (profile.displayType === 'PUBLIC_USERNAME') {
    return profile.handle;
  }

  if (profile.title) {
    return profile.title;
  }

  if (profile.firstName && profile.firstName) {
    return [profile.firstName, profile.lastName].join(' ');
  }

  return t('collectorProfileStrings.profileViewPage.unknown');
};

export const getProfileAbreviation = (profile: Profile): string => {
  const title = getProfileTitle(profile);
  const array = title.split(' ').map((e) => e[0] ?? '');

  if (array.length > 1) {
    array[array.length - 1] = `/${array[array.length - 1]}`;
    return array.reduce((curr, prev) => curr + prev).toUpperCase();
  }
  return array[0].toUpperCase();
};

export const getInitialCapitalized = (s: string): string => s[0]?.toUpperCase() ?? '';

export const getAbbreviation = (text: string | string[], joinText = ' '): string => {
  const words: string[] = typeof text === 'string' ? text.split(' ') : text;
  return words.map(getInitialCapitalized).join(joinText);
};

export const getProfileLocation = <
  T extends {
    locations?: {
      city?: string | null;
      country?: string | null;
    }[];
  }
>(
  profile?: { context?: T | null } | null
): string => getContextLocation(profile?.context);

const collectionTypeKey: Partial<Record<Enum_Collection_Type_Enum, string>> = {
  [Enum_Collection_Type_Enum.Created]: 'createdCollection',
  [Enum_Collection_Type_Enum.Owned]: 'ownedCollection',
  [Enum_Collection_Type_Enum.Managed]: 'managedCollection',
};

export const getArtworkToCollection = (artwork: any, collectionType: Enum_Collection_Type_Enum) => {
  const key = collectionTypeKey[collectionType];
  if (!key) {
    return {
      id: 0,
      isPublic: false,
    };
  }

  const { id: possibleId1, is_public: possiblePublic1 } =
    artwork?.collections?.find(
      (artworkToCollection: any) => artworkToCollection?.Collection?.type === collectionType
    ) ?? {};

  const { id: possibleId2, is_public: possiblePublic2 } = artwork?.[key]?.[0] ?? {};

  return {
    id: possibleId1 ?? possibleId2 ?? 0,
    isPublic: possiblePublic1 ?? possiblePublic2 ?? false,
  };
};

export const getArtworkMintType = (artwork: any, collectionType: Enum_Collection_Type_Enum): string => {
  let transferStatus = '';

  const { isPublic } = getArtworkToCollection(artwork, collectionType);

  if (artwork?.transaction_histories?.length) {
    if (
      [Enum_Transaction_Status_Enum.Initialized, Enum_Transaction_Status_Enum.Pending].includes(
        artwork?.transaction_histories[0]?.status
      )
    ) {
      transferStatus = t('dashboardStrings.manageArt.card.title.transferring');
    } else if (artwork.transaction_histories[0].status === Enum_Transaction_Status_Enum.Accepted) {
      transferStatus = t('dashboardStrings.manageArt.card.title.transferred');
    }
  }

  if (!isPublic && artwork?.published_date) {
    return t('dashboardStrings.manageArt.card.private');
  }
  if (!isPublic || !artwork?.published_date) {
    return t('dashboardStrings.manageArt.card.draft');
  }
  if (artwork.is_hard_minted) {
    return t('dashboardStrings.manageArt.card.hardMint');
  }

  return `${t('dashboardStrings.manageArt.card.softMint')}${transferStatus}`;
};

export const getArtworkMintTypeByParams = (
  isPublic: boolean,
  publishedDate?: Maybe<string | Date>,
  isHardMinted?: boolean,
  transactionStatus?: Enum_Transaction_Status_Enum
): string => {
  let transferStatus = '';

  if (transactionStatus) {
    if ([Enum_Transaction_Status_Enum.Initialized, Enum_Transaction_Status_Enum.Pending].includes(transactionStatus)) {
      transferStatus = t('dashboardStrings.manageArt.card.title.transferring');
    } else if (transactionStatus === Enum_Transaction_Status_Enum.Accepted) {
      transferStatus = t('dashboardStrings.manageArt.card.title.transferred');
    }
  }

  if (!isPublic && publishedDate) {
    return t('dashboardStrings.manageArt.card.private');
  }
  if (!isPublic || !publishedDate) {
    return t('dashboardStrings.manageArt.card.draft');
  }
  if (isHardMinted) {
    return t('dashboardStrings.manageArt.card.hardMint');
  }

  return `${t('dashboardStrings.manageArt.card.softMint')}${transferStatus}`;
};

export const getEditionMintType = ({
  is_public,
  publish_date,
}: Pick<Edition, 'is_public' | 'publish_date'>): string => {
  // TODO: set this to transferred when all prints are transferred
  if (!is_public && publish_date) {
    return t('dashboardStrings.manageArt.card.private');
  }
  if (!is_public) {
    return t('dashboardStrings.manageArt.card.draft');
  }
  return `${t('dashboardStrings.manageArt.card.softMint')}`;
};

export const getArtworkTransferUrl = (
  artworkId: Artwork['id'],
  transferState: string | ManageArtworkTransferStateType
): string => `/dashboard/${transferState === 'IN_PROGRESS' ? 'manage-transfer' : 'transfer-artwork'}/${artworkId}`;

export const buildTransferUrl = (
  artworkId: number,
  print?: TransferPrintData,
  transferState?: ManageArtworkTransferStateType
): string => {
  const statusPath = transferState === 'IN_PROGRESS' ? 'manage-transfer' : 'transfer-artwork';
  if (!print) {
    return `/dashboard/${statusPath}/${artworkId}`;
  }
  if (print.printId) {
    return `/dashboard/${statusPath}/${artworkId}/${print.printId}`;
  }
  const params: TransferPrintParams = {
    artworkId: artworkId.toString(),
    editionByTypeId: print.editionByType.id.toString(),
    printNumber: print.number.toString(),
  };
  return generatePath(DashboardRoutesConfig.transferPrint, params);
};

export const getArtistUrl = (
  profile?:
    | (Pick<Profile, 'handle'> & {
        type?: ProfileType;
      })
    | null
) => {
  const handle = profile?.handle ? `/${profile?.handle}` : '/';
  if (profile?.type === 'COLLECTOR') {
    return `/collector${handle}`;
  }
  if (profile?.type === 'GALLERY') {
    return `/gallery${handle}`;
  }
  return handle;
};

const PPI = 300;

const conversionTable: Record<ArtworkMeasureUnit, Record<ArtworkMeasureUnit, number>> = {
  [ArtworkMeasureUnit.American]: {
    [ArtworkMeasureUnit.American]: 1,
    [ArtworkMeasureUnit.Metric]: 1 / 0.3937,
    [ArtworkMeasureUnit.Digital]: PPI,
  },
  [ArtworkMeasureUnit.Metric]: {
    [ArtworkMeasureUnit.American]: 0.3937,
    [ArtworkMeasureUnit.Metric]: 1,
    [ArtworkMeasureUnit.Digital]: PPI / 2.539998628400741,
  },
  [ArtworkMeasureUnit.Digital]: {
    [ArtworkMeasureUnit.American]: 1 / PPI,
    [ArtworkMeasureUnit.Metric]: 2.539998628400741 / PPI,
    [ArtworkMeasureUnit.Digital]: 1,
  },
};

export const convertUnit = (from: ArtworkMeasureUnit, to: ArtworkMeasureUnit, value: number, decimals = 4): number => {
  const factor = conversionTable[from][to];
  const accuracy = 10 ** decimals;
  return Math.round(value * factor * accuracy) / accuracy;
};

export const cmToInch = (value: number | string | undefined | null) => {
  const parsedValue = parseFloat(value?.toString() || '0');
  if (value && parsedValue) {
    return (parsedValue || 0) * 0.3937;
  }
  return undefined;
};

export const inchToCm = (value: number | string | undefined | null) => {
  const parsedValue = parseFloat(value?.toString() || '0');
  if (value && parsedValue) {
    return (parsedValue || 0) / 0.3937;
  }
  return undefined;
};

export const wrapName = (width: number | string = 'auto', maxWidth: number | string = 'auto'): React.CSSProperties => ({
  width,
  maxWidth,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

export const getKaleidoCoinsTtile = (
  section: 'SERIES' | 'GROWTH' | 'PROFILE' | 'ART_PUBLICATION' | 'KALEIDO_CARD'
): string => t(`dashboardStrings.kaleidoCoins.titleContainer.${section}`);

export const makeCollectorProfile = (handle?: string): string => `${URL_LINK}/collector/${handle || 'handle'}`;

export const transferRoute = (artworkId: number | string) =>
  DashboardRoutesConfig.transfer.replace(':artworkId', artworkId.toString());

export const manageTransferRoute = (artworkId: number | string, printId?: number) =>
  generatePath(DashboardRoutesConfig.manageTransfer, { artworkId, printId });

export const formatNumberWithThousandsSeparator = (number: number) => number.toLocaleString('en');

export const roundUpToGivenDecimals = (number: number, upToDecimals: number): number => {
  const decimals = 10 ** upToDecimals;
  return Math.round(number * decimals) / decimals;
};

export const formatNumberToLocale = (number: number, locale?: BrowserLocale): string =>
  new Intl.NumberFormat(locale ?? 'en-US').format(number);

export const createCustomUrl = (link: string): string => {
  let url_verified = link;
  url_verified = url_verified.replace(/^(((https|http|):)\/\/(www\.)?)/, '');

  return url_verified;
};

/**
 * Clean a route path url.
 * - replace all // with /
 * @param url route path
 * @returns
 */
export const cleanUrl = (url: string): string => url?.replaceAll('//', '/') ?? '';

export const getHTMLVideoDuration = (video: HTMLVideoElement | null, fallbackValue: number) => {
  if (!video || video.duration === undefined || video.duration === Infinity || Number.isNaN(video.duration)) {
    return fallbackValue;
  }
  return video.duration;
};

export const normalize = (value: number, min: number, max: number): number => ((value - min) * 100) / (max - min);

export const appendToFileName = (fileName: string, suffix: string): string => {
  const dotIndex = fileName.lastIndexOf('.');
  if (dotIndex === -1) {
    return `${fileName}${suffix}`;
  }
  return `${fileName.substring(0, dotIndex)}${suffix}${fileName.substring(dotIndex)}`;
};

const normalizeIndexForCompareFn = (index: number): number => (index !== -1 ? index : 0);
/**
 * Compare items based on the order of the array items
 */
export const compareByArrayOrder =
  (array: unknown[]) =>
  (a: unknown, b: unknown): number => {
    const indexA = array.indexOf(a);
    const indexB = array.indexOf(b);
    if (indexA === -1) {
      logger.error(Error(`Value "${a}" not found in array: ${JSON.stringify(array)}`));
    }
    if (indexB === -1) {
      logger.error(Error(`Value "${b}" not found in array: ${JSON.stringify(array)}`));
    }
    return normalizeIndexForCompareFn(indexA) - normalizeIndexForCompareFn(indexB);
  };

export const getAcronym = (value: string): string => {
  if (typeof value !== 'string') {
    return '';
  }

  return value
    .replaceAll(/[^a-zA-Z ]/g, '')
    .split(' ')
    .map((p) => p.trim())
    .filter((s) => s.length)
    .map((a) => a[0].toUpperCase())
    .join('');
};

/**
 * Parse a value to number and call callback on success.
 * @param callback
 * @returns
 */
export const parseIntWithCallback =
  (callback: (parsed: number) => void) =>
  (value: unknown): void => {
    if (typeof value !== 'string') {
      return;
    }
    const maybeNumber = parseInt(value, 10);
    if (Number.isNaN(maybeNumber)) {
      return;
    }
    callback(maybeNumber);
  };

export const replaceBreakTagsWithNewline = (text?: string): string => {
  if (!text) {
    return '';
  }

  return text.replaceAll('<br/>', '\n');
};

/**
 * Converts string to uppper camel case
 * https://lodash.com/docs/4.17.15#camelCase
 * @param string
 * @returns string
 */
export const toUpperCamelCase = (str: string): string => _.upperFirst(_.camelCase(str));

export const formatDate = (year: number, month?: number | null, day?: number | null, locale?: string): string => {
  const date = new Date(year, month ?? 0, day ?? 1);
  const formatOptions: Intl.DateTimeFormatOptions = {
    year: 'numeric',
  };
  if (month) {
    formatOptions.month = 'long';
  }
  if (day && month) {
    formatOptions.day = 'numeric';
  }
  return date.toLocaleDateString(locale, formatOptions);
};

export const getEditionPriceType = (edition: Pick<Edition, 'price'> | Edition['price']): PrintPriceType => {
  const price = typeof edition === 'object' ? edition?.price : edition;
  return price ? PrintPriceType.FIXED : PrintPriceType.CUSTOM;
};

export const getEditionCurrency = (edition: Pick<Edition, 'currency'> | Edition['currency']): CurrencyTypeEnum => {
  const currency = typeof edition === 'object' ? edition?.currency : edition;
  if (isSomeEnum(CurrencyTypeEnum)(currency)) {
    return currency;
  }
  logger.warn(`"${currency}" not a valid CurrencyTypeEnumValue`);
  return CurrencyTypeEnum.$;
};

export const roundTo = (num: number, to: number): number => Math.round(num / to) * to;

export const preloadPrimaryImage = (artworkId: number, primaryImageMetadataId: number): void => {
  const image = new Image();
  image.src = getCDNImageUrl({
    target: 'Artwork',
    targetId: artworkId,
    fileId: primaryImageMetadataId || 0,
    purpose: 'Primary',
    meta: { size: 'large' },
  });
};

export const getSocialLinkURL = (socialLinks: any[], socialPlatform: string): string => {
  const index = socialLinks.findIndex((link) => link.platform === socialPlatform);

  if (index !== -1) {
    return socialLinks[index].URL;
  }
  return '';
};

export const getArtistProfileURL = (artistHandle: string): string =>
  ['local', 'staging'].includes(ENV_TYPE)
    ? `https://staging.kaleido.art/${artistHandle}`
    : `https://kaleido.art/${artistHandle}`;

export const isCustomEditionType = (editionType: string): boolean => !isSomeEnum(DefaultEditionType)(editionType);

export const escapeGqlLikeWildcards = (text: string): string => {
  const regex = /[_%]/g;
  return text?.replace(regex, (match) => `\\${match}`);
};

export function isInvalidDate(date: Date | null | undefined): boolean {
  if (!date) {
    return true;
  }
  return Object.prototype.toString.call(date) === '[object Date]' && isNaN(date.getTime());
}

export function isMyWebsiteDarkTheme(theme?: Enum_My_Website_Theme_Enum): boolean {
  return theme === Enum_My_Website_Theme_Enum.Dark;
}

export const getLinkToProfile = (
  profileType: Enum_Context_Type_Enum,
  profile?: Pick<Profile, 'handle'> | null
): string => {
  if (!profile) {
    return '';
  }
  switch (profileType) {
    case Enum_Context_Type_Enum.Artist:
      return `/${profile?.handle}`;
    case Enum_Context_Type_Enum.Collector:
      return `/collector/${profile?.handle}`;
    case Enum_Context_Type_Enum.Gallery:
      return `/gallery/${profile?.handle}`;
    default:
      return '';
  }
};
