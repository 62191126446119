import { useEffect, useMemo, useState } from 'react';

export default function useOnScreen(ref: any) {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting));

  useEffect(() => {
    observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  }, []);

  return isIntersecting;
}

// used for analytics impressions
export function useOnScreenImpressions(ref: any, threshold = 1) {
  const [isIntersecting, setIsIntersecting] = useState(false);

  // threshold is used to determine how much of the DIV we need to see in order to consider it as an impression
  // 1 means the full image is in the view
  // TODO: please move this value to a constants file
  const observer = useMemo(
    () => new IntersectionObserver(([entry]) => setIsIntersecting(entry.isIntersecting), { threshold }),
    []
  );

  useEffect(() => {
    if (!ref.current) {
      return undefined;
    }
    observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return isIntersecting;
}
