import { makeStyles } from '@material-ui/styles';
import MultilineInput from 'common/components/MultilineInput/MultilineInput';
import Section from 'common/components/Section/Section';
import { ArtworkStatementConfigValues } from 'common/features/FormikConfigs/components/Artwork';
import { UseFormikSection } from 'common/features/FormikConfigs/utils/formikTypeUtils';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { useIsMobileScreen } from '../../../../../../../../common/hooks/utils/useIsMobileScreen';

const customStyle = makeStyles({
  container: {
    backgroundColor: 'white',
  },
  section: {
    width: '100%',
  },
});

export const ARTWORK_STATEMENT_LENGTH = 5000;

export const ArtworkStatementSection = <T extends ArtworkStatementConfigValues = ArtworkStatementConfigValues>({
  formik,
  title,
  description,
}: UseFormikSection<T> & { title?: string; description?: string }): ReturnType<
  React.FC<UseFormikSection<T> & { title: string; description: string }>
> => {
  const { t } = useTranslation();
  const customClasses = customStyle();
  const isMobile = useIsMobileScreen();

  return (
    <Section
      title={title !== undefined ? title : t('addArtworkStrings.storytelling.statement')}
      description={
        description && description?.length > 0 ? description : t('addArtworkStrings.storytelling.statementDescription')
      }
      className={customClasses.section}
      style={isMobile ? {} : { marginTop: '40px' }}
    >
      <MultilineInput
        name="statement"
        variant="outlined"
        className={customClasses.container}
        value={formik.values.statement}
        maxLength={ARTWORK_STATEMENT_LENGTH}
        maxRows={5}
        onChange={formik.handleChange}
        placeholder={t('addArtworkStrings.storytelling.statementPlaceholder')}
        error={!!formik.errors.statement}
        helperText={formik.errors.statement}
      />
    </Section>
  );
};
