import { Grid } from '@material-ui/core';
import React from 'react';

export const FooterLayout: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => (
  <Grid
    container
    direction="row"
    justifyContent="space-between"
    alignItems="center"
    wrap="nowrap"
    style={{ flexWrap: 'nowrap', gap: '4px' }}
  >
    {children}
  </Grid>
);
