import { DashboardRoutesConfig } from 'stacks/Dashboard/common/DashboardRoot/configs/routesConfigs';
import type { Category, TargetAction } from 'common/features/Analytics/utils/analyticsTypes';
import { ROUTES } from 'routes/_definition';

// EVENTS USED ON Mobile/ Frontend/ Backend
export const events = {
  ACCOUNT: {
    ADD_ARTWORK_DETAILS: true,
    ADD_ARTWORK_COMMERCIAL: true,
    ADD_ARTWORK_CONGRATS_PAGE: true,
    ADD_ARTWORK_REVIEW: true,
    ADD_ARTWORK_SERIES: true,
    ADD_ARTWORK_SERIES_EDIT: true,
    ADD_ARTWORK_NEW_FROM_CONGRATS: true,
    ADD_ARTWORK_NEW_FROM_EMPTY_WINDOW: true,
    ADD_ARTWORK_END: true,
    ADD_ARTWORK_IMAGERY: true,
    ADD_ARTWORK_IMAGERY_SAVE: true,
    ADD_ARTWORK_IMAGERY_PRIMARY_IMAGE: true,
    ADD_ARTWORK_IMAGERY_SECONDARY_IMAGE: true,
    ADD_ARTWORK_IMAGERY_ADD_SECONDARY_IMAGE: true,
    ADD_ARTWORK_IMAGERY_DELETE_SECONDARY_IMAGE: true,
    ADD_ARTWORK_IMAGERY_PRIMARY_IMAGE_UPLOAD: true,
    ADD_ARTWORK_IMAGERY_PRIMARY_IMAGE_CHANGE: true,
    ADD_ARTWORK_IMAGERY_NEXT: true,
    ADD_ARTWORK_STORYTELLING: true,
    ADD_ARTWORK_STORYTELLING_ADD_AUDIO: true,
    ADD_ARTWORK_STORYTELLING_NEXT: true,
    ADD_ARTWORK_STORYTELLING_PAGE_LOAD: true,
    ADD_ARTWORK_DETAILS_NEXT: true,
    ADD_ARTWORK_DETAILS_PAGE_LOAD: true,
    ADD_ARTWORK_ARTIST: true,
    ADD_ARTWORK_GALLERY: true,
    ADD_ARTWORK_IMAGERY_NEW_PAGE_LOAD: true,
    ADD_ARTWORK_SERIES_NEXT: true,
    ADD_ARTWORK_SERIES_PAGE_LOAD: true,
    ADD_ARTWORK_COMMERCIAL_PAGE_LOAD: true,
    ADD_ARTWORK_COMMERCIAL_NEXT: true,
    ADD_ARTWORK_REVIEW_PAGE_LOAD: true,
    ADD_ARTWORK_REVIEW_NEXT: true,
    ADD_ARTWORK_CONGRATS_PAGE_LOAD: true,
    ADD_ARTWORK_PUBLISHED: true,
    ADD_ARTWORK_CLOSED: true,
    CLICK_ADD_ARTWORK: true,
    CLICK_EDIT_ARTWORK: true,
    UPGRADE_USER: true,
    UPGRADE_USER_PASSWORD: true,
    UPGRADE_USER_REMIND_ME_LATER_CLICK: true,
    UPGRADE_USER_CONTINUE_CLICK: true,
    EDITION_PUBLISHED: true,
    CLOSE: true,
  },
  ARTWORK_PURCHASE: {
    INITIATE_PURCHASE_ERROR: true,
    INITIATE_PURCHASE_SUCCESS: true,
  },
  REGISTER: {
    CREATE_ACCOUNT_STEP0_PAGE_LOAD: true,
    CREATE_ACCOUNT_STEP0: true,
    CREATE_ACCOUNT_STEP0_ARTIST: true,
    CREATE_ACCOUNT_STEP0_COLLECTOR: true,
    CREATE_ACCOUNT_STEP0_GALLERY: true,
    CREATE_ACCOUNT_STEP1_VERIFICATION_CODE: true,
    CREATE_ACCOUNT_STEP1_PAGE_LOAD: true,

    CREATE_PROFILE_STEP1: true,
    CREATE_PROFILE_STEP1_NEXT: true,
    CREATE_PROFILE_STEP1_BACK: true,
    CREATE_PROFILE_STEP1_PAGE_LOAD: true,
    CREATE_PROFILE_STEP2: true,
    CREATE_PROFILE_STEP2_NEXT: true,
    CREATE_PROFILE_STEP2_BACK: true,
    CREATE_PROFILE_STEP2_PAGE_LOAD: true,
    CREATE_PROFILE_STEP3: true,
    CREATE_PROFILE_STEP3_NEXT: true,
    CREATE_PROFILE_STEP3_BACK: true,
    CREATE_PROFILE_STEP3_PAGE_LOAD: true,
    CREATE_PROFILE_STEP4: true,
    CREATE_PROFILE_STEP4_NEXT: true,
    CREATE_PROFILE_STEP4_BACK: true,
    CREATE_PROFILE_STEP4_PAGE_LOAD: true,
    CREATE_PROFILE_STEP5_CONGRATS: true,
    CREATE_PROFILE_STEP5_CONGRATS_PAGE_LOAD: true,
    CREATE_PROFILE_COMPLETED: true,

    // New onboarding older ones are deprecated
    CREATE_USER_CLICK: true,
    CREATE_USER_ACCOUNT: true,
    CREATE_USER_VERIFY: true,
    CREATE_ARTIST_STEP1: true,
    CREATE_ARTIST_STEP2: true,
    CREATE_ARTIST_STEP3: true,
    CREATE_ARTIST_STEP4: true,
    CREATE_ARTIST_STEP5: true,
    CREATE_COLLECTOR_STEP1: true,
    CREATE_COLLECTOR_STEP2: true,
    CREATE_COLLECTOR_STEP3: true,
    CREATE_COLLECTOR_STEP4: true,
    CREATE_COLLECTOR_STEP5: true,
    CREATE_COLLECTOR_STEP6: true,

    CREATE_USER_REGISTER_DISPLAY: true,
    CREATE_USER_REGISTER_FROM: true,
    SELECT_PROFILE_TYPE: true,
    CONTACT_FOR_GALLERY_CLICK: true,
    BACK_BUTTON_CLICK: true,
    DO_NOT_SHARE_LOCATION_CLICK: true,
    ALTERNATIVE_LOCATION_SELECTION: true,
    SHARE_LOCATION_CLICK: true,
  },
  LOGIN: {
    PAGE_LOAD: true,
    LOG_IN: true,
    LOG_IN_GOOGLE: true,
    LOG_IN_APPLE: true,
  },
  ARTWORK: {
    TRANSFER: true,
    UPLOAD_IMAGE: true,
    TRANSFER_CANCEL: true,
    ADD_NEW: true,
    ADD_NEW_BUTTON: true,
    CLICK_MAKE_PRIVATE: true,
  },
  AUDIO: {
    PLAYER: true,
    CLICK_PLAY: true,
    CLICK_PAUSE: true,
    SKIP_AUDIO: true,
    AUDIO_END: true,
  },
  INVITE: {
    COLLECTOR: true,
    ARTIST: true,
    GALLERY: true,
    COLLECTOR_INVITE_CLICK: true,
    ARTIST_INVITE_CLICK: true,
    COLLECTOR_REMIND: true,
    ARTIST_REMIND: true,
    ARTIST_REVOKE: true,
  },
  SIDEBAR: {
    ITEMS_OPEN_TAB: true,
  },
  LANDING: {
    CREATE_PROFILE_INIT: true,
    CREATE_PROFILE_ARTIST: true,
    CREATE_PROFILE_COLLECTOR: true,
    CREATE_PROFILE_GALLERY: true,
    CREATE_PROFILE_COLLECTOR_COLLECTION: true,
    CREATE_PROFILE_ARTIST_COLLECTION: true,
  },
  LOCATION: {
    PERMISSION_DENIED: true,
  },
  SHARED: {
    COLOR_CHANGER: true,
  },
  USER_SIDEBAR: {
    TAB_CLICKED: true,
  },
  KALEIDO_CARD: {
    GET_STARTED: true,
    SAVE_CHANGES: true,
    PAGE_HIT: true,
    AUDIO_PLAYBACK: true,
    OPEN_URL_AVAILABLE_ARTWORKS: true,
    OPEN_URL_ABOUT_ARTIST: true,
    OPEN_URL_PINNEDLINK: true,
    OPEN_URL_WEBSITE: true,
    OPEN_URL_CUSTOM_LINK: true,
    OPEN_URL_PRESS_LINK: true,
    OPEN_URL_INSTAGRAM: true,
    OPEN_URL_TWITTER: true,
    OPEN_URL_FACEBOOK: true,
    OPEN_URL_PINTEREST: true,
    OPEN_URL_TIKTOK: true,
    OPEN_URL_YOUTUBE: true,
    OPEN_URL_SOCIAL_LINK: true,
    OPEN_URL_AVAILABLE_ARTWORKS_V2: true,
    OPEN_URL_ABOUT_ARTIST_V2: true,
    OPEN_URL_KALEIDO_CARD_TEXT_HOME_LINK: true,
    OPEN_URL_KALEIDO_CARD_ICON_HOME_LINK: true,
    OPEN_KALEIDO_REGISTER_URL: true,
    OPEN_URL_ARTWORK_V2: true,
    CLICK_ON_LINK: true,
    OPEN_URL_CREATE_BIOLINK: true,
  },
  WAITLIST: {
    SELECTED_LANGUAGE: true,
    CALENDLY_SCHEDULE: true,
  },
  AB_TEST: {
    KALEIDO_CARD_VERSION: true,
  },
  PAGE: {
    LOCATION_URL: true,
  },
  TUTORIALS: {
    START: true,
    SKIP: true,
    FINISH: true,
    TOOLTIP_PRIMARY_CLICK: true,
    TOOLTIP_SECONDARY_CLICK: true,
    RESET: true,
    START_VIDEO: true,
  },
  TUTORIALS_2: {
    WELCOME: true,
    STEPS: true,
    NO_DISCOUNT_CODE: true,
    CLAIMED_DISCOUNT_CODE: true,
    ADD_ARTWORK: true,
    SKIP_ADD_ARTWORK: true,
  },
  SHOWROOM: {
    CREATE: true,
    STEP1_SUBMIT: true,
    STEP2_SUBMIT: true,
    STEP3_SUBMIT: true,
    STEP4_SUBMIT: true,
    SUBMIT_NEXT: true,
    BACK: true,
    PUBLISH: true,
    VIEW_PROFILE: true,
    VIEW_ARTWORK: true,
  },
  DIALOG: {
    GUEST_PASSWORD: true,
    OPEN_FAVOURITES_COUNT: true,
    OPEN_KC_PRESENTATION_LOCKED: true,
    CLOSE_KC_PRESENTATION_LOCKED: true,
  },
  SHOWCASE: {
    PAGE_HIT: true,
    CLICK_CLOSE_ART_SHOWCASE: true,
    CLICK_EDIT_ARTWORK: true,
    CLICK_SHARE: true,
    CLICK_SHARE_MOBILE: true,
    CLICK_SWITCH_MODE: true,
    CLICK_PROCESS: true,
    CLICK_VUFORIA: true,
    CLICK_AUDIO_PRESENTATION: true,
    CLICK_VIDEO_PRESENTATION: true,
    CLICK_NEXT_ARTWORK: true,
    CLICK_VIEW_MORE_ART: true,
    CLICK_OPEN_DETAILS: true, // When click or swipe to open profile sidebar
    CLOSE_ARTIST_SIDEBAR: true,
    CLICK_CHANGE_TAB: true,
    CONTACT_ARTIST_FOR_AVAILABILITY: true,
    CONTACT_ARTIST_FOR_AVAILABILITY_USER: true,
    CONTACT_ARTIST_FOR_AVAILABILITY_UNKNOWN_USER: true,
    CLOSE_CONTACT_ARTIST_FOR_AVAILABILITY: true,
    OPEN_ARTWORK_DETAILS: true, // When click or swipe to open showcase details
    CLOSE_ARTWORK_DETAILS: true, // When click or swipe to close showcase details
  },
  MY_WEBSITE_SETUP: {
    SETUP_STEP: true,
    SETUP_STEP_NO_DOMAIN: true,
    SETUP_STEP_EXISTING_DOMAIN: true,
    SETUP_SELECT_PAYMENT_PLAN: true,
    CHECK_OUT: true,
    LEARN_MORE: true,
    SEARCH_DOMAIN: true,
    SHOW_MORE_DOMAINS: true,
    CONFIRM_DOMAIN_INFO: true,
    SELECT_DOMAIN: true,
    CLOSE_SETUP: true,
    SETUP_STEP_BACK_BUTTON_CLICK: true,
    SUCCESS_PAGE_REDIRECT_TO_DASHBOARD: true,
    SUCCESS_PAGE_LAUNCH_MY_WEBSITE: true,
    APPLY_DISCOUNT_CODE_CLICK: true,
    UPGRADE_TO_MY_STUDIO: true,
  },
  PROFILE: {
    PAGE_HIT: true,
    CLICK_ARTWORK: true,
    CLICK_OPEN_FOLLOWS_MODALS: true,
    CLICK_CLOSE_FOLLOWS_MODALS: true,
    CLICK_VIEW_ALL_FOLLOWS_ARTISTS: true,
    CLICK_VIEW_ALL_FOLLOWS_COLLECTORS: true,
    CICK_VIEW_ALL_FAVORITES: true,
  },
  CAROUSELS: {
    CLICK_NEXT: true,
    CLICK_PREVIOUS: true,
    CLICK_SEE_ALL_BUTTON: true,
  },
  EXPLORE: {
    PROFILE_IMPRESSIONS: true,
    PROFILE_CLICK: true,
  },
  COLLECTIONS: {
    PROFILE_IMPRESSIONS: true,
    PROFILE_CLICK: true,
  },
  RECOMMENDATIONS: {
    PROFILE_IMPRESSIONS: true,
    PROFILE_CLICK: true,
  },
  MYFAVORITES: {
    PROFILE_IMPRESSIONS: true,
    PROFILE_CLICK: true,
  },
  MYRECOGNITIONS: {
    PROFILE_IMPRESSIONS: true,
    PROFILE_CLICK: true,
  },
  RECOGNIZE: {
    CLICK_CTA: true,
    CREATE_ACCOUNT_CLICK_CTA: true,
  },
  FAVORITES: {
    CLICK_CTA: true,
    CREATE_ACCOUNT_CLICK_CTA: true,
  },
  PRIZE: {
    PAGE_LOAD: true,
    CLICK_LINK: true,
    CLICK_LINK_SOCIAL_MEDIA: true,
    CLICK_EXPLORE_WINNERS: true,
    CLICK_ARTIST_PROFILE: true,
    CLICK_ARTWORK: true,
  },
  PERFORMANCE: {
    WEB_VITALS: true,
  },
  NOTIFICATIONS: {
    CLICK_OPEN: true,
    CLICK_CLOSE: true,
  },
  LIVE_PAGE: {
    IMPRESSIONS: true,
    CLICK: true,
  },
  SHOP_PAGE: {
    IMPRESSIONS: true,
    CLICK: true,
  },
  EXPLORE_ARTISTS_PAGE: {
    IMPRESSIONS: true,
    CLICK: true,
  },
  NEARBY_UNITY_LOCATION: {
    LOCATION_RECEIVED: true,
  },
  NEARBY_CURRENT_LOCATION: {
    ERROR: true,
  },
  NEARBY_ARTISTS_PAGE: {
    IMPRESSIONS: true,
    CHANGE_RADIUS: true,
  },
  NEARBY_ARTISTS_PAGE_GALLERY_CROUSEL: {
    IMPRESSIONS: true,
  },
  NEARBY_GALLERIES_PAGE: {
    IMPRESSIONS: true,
  },
  PRIZE_PAGE: {
    IMPRESSIONS: true,
    CLICK: true,
  },
  COLLECTION_PAGE: {
    IMPRESSIONS: true,
    CLICK: true,
  },
  FAVORITES_PAGE: {
    IMPRESSIONS: true,
    CLICK: true,
  },
  RECOGNITIONS_PAGE: {
    IMPRESSIONS: true,
    CLICK: true,
  },
  ARTIST_PROFILE: {
    IMPRESSIONS: true,
  },
  CONSUMER_PRODUCT_FOOTER: {
    CLICK_FILTER_APPLY: true, // when click on any filter
    CLICK_FILTER_APPLY_BUTTON: true, // when click on the "apply" button on the filters modal
    CLICK_FILTER_CLEAR: true, // when click on the "clear" button on the filters modal
    CLICK_CLOSE_FILTERS: true, // when click on the arrow button on the filters modal
    CLICK_SEARCH_BUTTON: true, // when click on the search button (desktop) to open the filters modal
    CLICK_MOBILE: true,
    CLICK_MENU_BUTTON: true,
  },
  LINK: {
    CLICK: true,
  },
  DOWNLOAD_APP_CTA: {
    CLICK_CLOSE: true,
    CLICK_DOWNLOAD: true,
  },
  VOTE_KALEIDO_CTA: {
    CLICK_CLOSE: true,
    CLICK_VOTE: true,
  },
  SWIPE: {
    LIVE_ARTWORK_LEFT: true,
  },
  HOMEPAGE: {
    CLICK_VOLUME_ICON: true,
    PLAY_VIDEO: true,
  },
  VIDEO: {
    PLAY: true,
    PAUSE: true,
    ENDED: true,
  },
  UNLOCK: {
    CLICK: true,
    CLICK_TOOLTIP: true,
  },
  ARTWORK_TAGS: {
    OPEN_PRIMARY: true,
    OPEN_SECONDARY: true,
    CLICK_SUGGESTED_PRIMARY: true,
    CLICK_SUGGESTED_SECONDARY: true,
    CLICK_PRIMARY: true,
    CLICK_SECONDARY: true,
    MOBILE_CLICK_PRIMARY: true,
    MOBILE_CLICK_SUGGESTED_PRIMARY: true,
  },
  DM: {
    OPEN_SIDEBAR: true,
    OPEN_PRIVATE_CHAT: true,
    CLOSE_PRIVATE_CHAT: true,
    BLOCK_USER: true,
    RECEIVED_MESSAGE: true,
    UNREAD_MESSAGE_LANDING: true,
    MESSAGE: true,
    CREATE_ACCOUNT_CLICK_CTA: true,
    CLICK_CHAT_HEADER: true,
  },
  PERMISSION: {
    ENABLE: true,
    NOT_NOW: true,
    CLOSE_MODAL: true,
  },
  GUEST_MODALS: {
    OPEN_FAVORITES: true, // add analytics.postData function for when this modal is opened
    OPEN_FOLLOW: true, // add analytics.postData function for when this modal is opened
    OPEN_DM: true, // add analytics.postData function for when this modal is opened
    OPEN_PAGE: true, // this will be used for the modal that appears after 90 seconds of every page of the consumer product
    CLOSE_FAVORITES: true, // add analytics.postData function for when the user clicked the x button or outside the modal
    CLOSE_FOLLOW: true, // add analytics.postData function for when the user clicked the x button or outside the modal
    CLOSE_DM: true, // add analytics.postData function for when the user clicked the x button or outside the modal
    CLOSE_PAGE: true, // this will be used for the modal that appears after 90 seconds of every page of the consumer product when the user clicked the x button or outside the modal
    LOGIN_CLICK: true, // add analytics.postData function for when the user clicked the login button
    CREATE_ACCOUNT_CLICK: true, // add analytics.postData function for when the user clicked the register button
  },
  BUY_ARTWORK: {
    CLICK_BUY: true,
    CLICK_CONTINUE: true,
    CLICK_CANCEL: true,
    CLICK_CLOSE_BUTTON: true,
    CLICK_BACK_TO_ARTIST_PROFILE: true,
    CLICK_STRIPE_BACK_BUTTON: true,
  },
  ART_SHOW: {
    CLICK_VIEW_ART_SHOW_BUTTON: true,
    CLICK_VIEW_ARTWORKS_BUTTON: true,
    CLICK_FILTERS_BUTTON: true,
  },
  MUSEUM: {
    CLICK_VIEW_ART_SHOW_BUTTON: true,
    CLICK_VIEW_ARTWORKS_BUTTON: true,
    CLICK_FILTERS_BUTTON: true,
    CLICK_FILTER_CLEAR: true,
    CLICK_FILTER_APPLY: true,
  },
  WELCOME_BANNER: {
    CLICK_VIEW_PROFILE: true,
  },
  EDIT_PROFILE: {
    CHANGE_TAB: true,
    SAVE_CHANGES: true,
    EDIT_APPEARANCE: true,
    OPEN_REORDER_ARTWORKS_MODAL: true,
  },
  SHOPPING_CART: {
    ADD_TO_CART: true,
    REMOVE_FROM_CART: true,
    ADD_TO_CART_USER_TRAP: true,
  },
  MY_STUDIO: {
    MY_WEBSITE_GET_STARTED: true,
    OPEN_UPGRADE_TO_MY_STUDIO_MODAL: true,
    CLOSE_UPGRADE_TO_MY_STUDIO_MODAL: true,
    UPGRADE_PLAN_BUTTON_CLICK: true,
    ADD_DOMAIN_CLICK: true,
    LEARN_MORE_CLICK: true,
    OPEN_LEARN_MORE_MODAL_CLICK: true,
    CLOSE_LEARN_MORE_MODAL_CLICK: true,
    UPGRADE_MODAL_SELECT_PLAN: true,
    UPGRADE_MODAL_UPGRADE_NOW_CLICK: true,
    UPGRADE_MODAL_CLOSE: true,
    MY_BIO_LINK_COPY_LINK: true,
    MY_BIO_LINK_WATCH_VIDEO: true,
    COMMUNITY_COPY_INVITE_LINK: true,
    COMMUNITY_INVITE_VIA_EMAIL: true,
    COMMUNITY_VIEW_MORE_ARTISTS: true,
    COMMUNITY_VIEW_MORE_COLLECTORS: true,
    COMMUNITY_ARROW_BUTTON_CLICK: true,
    COMMUNITY_FOLLOWERS_VIEW_ALL: true,
    COMMUNITY_FOLLOWERS_ARRANGE_ARTISTS: true,
    COMMUNITY_SWITCH_TAB: true,
    TRY_MY_WEBSITE_BUTTON_CLICK: true,
    MY_WEBSITE_VIEW_EXAMPLE_CLICK: true,
    MY_WEBSITE_CUSTOMISE_BUTTON_CLICK: true,
    MY_WEBSITE_CHANGE_DOMAIN_BUTTON_CLICK: true,
    MY_WEBSITE_CUSTOMISE_NAVIGATION_TYPE: true,
    MY_WEBSITE_CUSTOMISE_LAYOUT_1: true,
    MY_WEBSITE_CUSTOMISE_LAYOUT_2: true,
    MY_WEBSITE_CUSTOMISE_LAYOUT_3: true,
    MY_WEBSITE_CUSTOMISE_THEME: true,
    MY_WEBSITE_REORDER_ARTWORKS_MODAL: true,
    MY_WEBSITE_REORDERED_ARTWORKS: true,
    MY_WEBSITE_CUSTOMISE_FILTERS: true,
    MY_WEBSITE_CUSTOMISE_CLOSE_CLICK: true,
    MY_WEBSITE_CUSTOMISE_PUBLISH_CLICK: true,
    MY_WEBSITE_CUSTOMISE_PREVIEW_CLICK: true,
    MY_WEBSITE_ADD_ARTWORK_BUTTON: true,
    MY_WEBSITE_UPDATE_PAYMENT_METHOD_BUTTON: true,
    MY_DOCUMENTS_GENERATE_CERTIFICATE_CLICK: true,
    MY_DOCUMENTS_DOWNLOAD_DOCUMENT: true,
    MY_DOCUMENTS_FILTERS: true,
    MY_DOCUMENTS_CERTIFICATES_MODAL_CLOSE: true, // NOTE: Use tags to differentiate between the different steps (SELECT_ARTWORKS, CONFIRM_GENERATION, GENERATED_CERTIFICATES)
    MY_DOCUMENTS_CERTIFICATES_MODAL_SELECT_ARTWORKS: true, // NOTE: send the array of the selected artworksId in the tags
    MY_DOCUMENTS_CERTIFICATES_MODAL_CONFIRM_GENERATION: true, // NOTE: send the array of the selected artworksId in the tags
    MY_DOCUMENTS_CERTIFICATES_MODAL_BACK_BUTTON: true,
    MY_DOCUMENTS_CERTIFICATES_MODAL_DOWNLOAD: true, // Used when downloading the certificates from the modal // NOTE: send the array of the selected artworksId in the tags
  },
  MILESTONES: {
    CLICK_GO_TO_EXPLORE: true,
    CLICK_LEARN_MORE: true,
  },
};

// Explore pages analytics
type TargetAnalytics<T extends Category, TA = TargetAction<T>> = {
  impression: TA;
  click: TA;
};

export const explorePagesTargets: {
  EXPLORE: TargetAnalytics<'EXPLORE'>;
  COLLECTIONS: TargetAnalytics<'COLLECTIONS'>;
  RECOMMENDATIONS: TargetAnalytics<'RECOMMENDATIONS'>;
  MYRECOGNITIONS: TargetAnalytics<'MYRECOGNITIONS'>;
} = {
  EXPLORE: {
    impression: {
      eventAction: 'PROFILE_IMPRESSIONS',
      eventCategory: 'EXPLORE',
    },
    click: {
      eventAction: 'PROFILE_CLICK',
      eventCategory: 'EXPLORE',
    },
  },
  MYRECOGNITIONS: {
    impression: {
      eventAction: 'PROFILE_IMPRESSIONS',
      eventCategory: 'MYRECOGNITIONS',
    },
    click: {
      eventAction: 'PROFILE_CLICK',
      eventCategory: 'MYRECOGNITIONS',
    },
  },
  COLLECTIONS: {
    impression: {
      eventAction: 'PROFILE_IMPRESSIONS',
      eventCategory: 'COLLECTIONS',
    },
    click: {
      eventAction: 'PROFILE_CLICK',
      eventCategory: 'COLLECTIONS',
    },
  },
  RECOMMENDATIONS: {
    impression: {
      eventAction: 'PROFILE_IMPRESSIONS',
      eventCategory: 'RECOMMENDATIONS',
    },
    click: {
      eventAction: 'PROFILE_CLICK',
      eventCategory: 'RECOMMENDATIONS',
    },
  },
};

export const pageHitIgnoredPaths = [
  DashboardRoutesConfig.share,
  DashboardRoutesConfig.addArtwork,
  DashboardRoutesConfig.publish,
  DashboardRoutesConfig.connections,
  DashboardRoutesConfig.community,
  DashboardRoutesConfig.profile,
  ROUTES.ONBOARDING_ARTIST,
  ROUTES.ONBOARDING_USER,
];
