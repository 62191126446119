import { IS_CUSTOM_DOMAINS_APP } from 'utils/constants';

export type FeatureFlagsState = { [k in FeatureFlags]: FeatureStatus };

export type FeatureStatus = 'active' | 'inactive';
export const featureStatusMap: { [k in FeatureStatus]: boolean } = {
  active: true,
  inactive: true,
};

// WARNING : DO NOT use . or : in the name of the feature flag
export type FeatureFlags =
  | 'admirationsKaleidoCard'
  | 'artShow'
  | 'artFeed'
  | 'artFeedNavbarV2'
  | 'artPrizeOnShowcase'
  | 'artShowcaseFTU'
  | 'artTransferPreferedLanguage'
  | 'artViewerSignup'
  | 'artistProfileNewFooter'
  | 'artistProfileReloaded'
  | 'artistShowroom'
  | 'artistSignup2'
  | 'autoplayFTUVideo'
  | 'autoOpenBuyModal'
  | 'buyArtwork'
  | 'coa'
  | 'coa2'
  | 'collectorProfileNewFooter'
  | 'connectionsAdmirationsTab'
  | 'consumerProductFTU'
  | 'destinations'
  | 'featuredPage'
  | 'disableFtuSkip'
  | 'discovery'
  | 'downloadcert'
  | 'favorites'
  | 'favoritesEngagement'
  | 'initialSignup'
  | 'landingPage2'
  | 'learnMore'
  | 'milestones'
  | 'newRegistrationAndOnboarding'
  | 'notifications'
  | 'print-button'
  | 'prints'
  | 'recognitionsTabArtistProfile'
  | 'showcase3.2'
  | 'ssrCoA'
  | 'tutorials'
  | 'globalTopNavbar'
  | 'unityWebView'
  | 'visibleConsole'
  | 'voteForKaleidoPopup'
  | 'waitlistSignup'
  | 'artistProfileV2'
  | 'directMessaging'
  | 'artShowcaseMobileV2'
  | 'kaleidoCardV3'
  | 'artShowcaseMobileSlider'
  | 'contentTranslation'
  | 'redirectFeatured'
  | 'recognitionsModal'
  | 'directMessagingChatSearch'
  | 'preSetTagsSuggestions'
  | 'mobileArtworkUpload'
  | 'artistSidebarV2'
  | 'directMessagingAnalytics'
  | 'preventAutoscrolling'
  | 'downloadAppPopup'
  | 'existingDomain'
  | 'closeAccount'
  | 'aboutPage'
  | 'loggedOutAccess'
  // Temporary flag to ignore explore as guest button until we definetly decide if we want to keep it or not
  | 'ignoreExploreAsGuestButton'
  | 'followInsteadOfRecognize'
  | 'developerPages'
  | 'collectorProfileV2'
  | 'globalBottomNavbar'
  | 'googleLogin'
  | 'buyModal'
  | 'shopPage'
  | 'cart'
  | 'arDeepLinkStaging'
  | 'loggedOutUserTraps'
  | 'universalSwipeHandlers'
  | 'editProfileMobile'
  | 'editCollectorProfileMobile'
  | 'home'
  | 'arScore'
  | 'preferredCurrency'
  | 'allowLinksOnDM'
  | 'kaliAutomatization'
  | 'artUniverse'
  | 'aiAudioStatement'
  | 'myDocuments'
  | 'applyToArtUniverse'
  | 'myAnalytics'
  | 'myBioLinkUpgrade'
  | 'milestonesV1'
  | 'dashboardV2'
  | 'loggedOutEcommerce'
  | 'openUpgradeToMyStudio'
  | 'myWebsite'
  | 'redirectToMyWebsite'
  | 'printsEditionsMyDocuments'
  | 'promptUpgradeToMyStudio'
  | 'generatedDocumentsEmails'
  | 'dynamicCV'
  | 'upgradeFromEmergingToProfessional'
  | 'dashboardFTUV2'
  | 'registerOnboardingV3'
  | 'myWebsitePreviewPublic'
  | 'applyDiscountCode'
  | 'mySubscribers'
  | 'tutorialCustomizeWebsiteFTU'
  | 'biolinkSectionReorder'
  | 'tutorialDashboardFTUNoTooltip'
  | 'analyzeMessageContent'
  | 'loggedOutEcommerce2';

export const defaultFeatureFlags: FeatureFlagsState = {
  waitlistSignup: localStorage.ff_waitlistSignup ?? 'active',
  coa: localStorage.ff_coa ?? 'active',
  artistShowroom: localStorage.ff_artistShowroom ?? 'active',
  artTransferPreferedLanguage: localStorage.ff_artTransferPreferedLanguage ?? 'active',
  artistProfileNewFooter: localStorage.ff_artistProfileNewFooter ?? 'active',
  buyArtwork: localStorage.ff_buyArtwork ?? 'active',
  collectorProfileNewFooter: localStorage.ff_collectorProfileNewFooter ?? 'active',
  tutorials: localStorage.ff_tutorials ?? 'active',
  milestones: localStorage.ff_milestones ?? 'active',
  coa2: localStorage.ff_coa2 ?? 'active',
  admirationsKaleidoCard: localStorage.ff_admirationsKaleidoCard ?? 'active',
  artistProfileReloaded: localStorage.ff_artistProfileReloaded ?? 'active',
  connectionsAdmirationsTab: localStorage.ff_connectionsAdmirationsTab ?? 'active',
  recognitionsTabArtistProfile: localStorage.ff_recognitionsTabArtistProfile ?? 'active',
  artistSignup2: localStorage.ff_artistSignup2 ?? 'active',
  artViewerSignup: localStorage.ff_artViewerSignup ?? 'inactive',
  initialSignup: localStorage.ff_initialSignup ?? 'active',
  landingPage2: localStorage.ff_landingPage2 ?? 'active',
  notifications: localStorage.ff_notifications ?? 'active',
  disableFtuSkip: localStorage.ff_disableFtuSkip ?? 'active',
  autoplayFTUVideo: localStorage.ff_autoplayFTUVideo ?? 'active',
  prints: localStorage.ff_prints ?? 'active',
  'showcase3.2': localStorage['ff_showcase3.2'] ?? 'active',
  discovery: localStorage.ff_discovery ?? 'active',
  'print-button': localStorage['ff_print-button'] ?? 'inactive',
  favorites: localStorage.ff_favorites ?? 'active',
  ssrCoA: localStorage.ff_ssrCoA ?? 'inactive',
  visibleConsole: localStorage.ff_visibleConsole ?? 'inactive',
  downloadcert: localStorage.ff_downloadcert ?? 'inactive',
  artPrizeOnShowcase: localStorage.ff_artPrizeOnShowcase ?? 'active',
  artFeed: localStorage.ff_artFeed ?? 'active',
  newRegistrationAndOnboarding: localStorage.ff_newRegistrationAndOnboarding ?? 'active',
  unityWebView: localStorage.ff_unityWebView ?? 'inactive',
  artFeedNavbarV2: localStorage.artFeedNavbarV2 ?? 'active',
  learnMore: localStorage.ff_learnMore ?? 'active',
  consumerProductFTU: localStorage.ff_consumerProductFTU ?? 'active',
  destinations: localStorage.ff_destinations ?? 'inactive',
  favoritesEngagement: localStorage.ff_favoritesEngagement ?? 'active',
  artShowcaseFTU: localStorage.ff_artShowcaseFTU ?? 'active',
  voteForKaleidoPopup: localStorage.ff_voteForKaleidoPopup ?? 'inactive',
  artistProfileV2: localStorage.ff_artistProfileV2 ?? 'active',
  featuredPage: localStorage.ff_featuredPage ?? 'active',
  directMessaging: localStorage.ff_directMessaging ?? IS_CUSTOM_DOMAINS_APP ? 'inactive' : 'active',
  artShowcaseMobileV2: localStorage.ff_artShowcaseMobileV2 ?? 'active',
  kaleidoCardV3: localStorage.ff_kaleidoCardV3 ?? 'active',
  artShowcaseMobileSlider: localStorage.ff_artShowcaseMobileSlider ?? 'active',
  recognitionsModal: localStorage.ff_recognitionsModal ?? 'inactive',
  contentTranslation: localStorage.ff_contentTranslation ?? 'active',
  redirectFeatured: localStorage.ff_redirectFeatured ?? 'inactive',
  directMessagingChatSearch: localStorage.ff_directMessagingChatSearch ?? 'active',
  preSetTagsSuggestions: localStorage.ff_preSetTagsSuggestions ?? 'active',
  mobileArtworkUpload: localStorage.ff_mobileArtworkUpload ?? 'active',
  artistSidebarV2: localStorage.ff_artistSidebarV2 ?? 'active',
  preventAutoscrolling: localStorage.ff_preventAutoscrolling ?? 'inactive',
  artShow: localStorage.ff_artShow ?? 'active',
  directMessagingAnalytics: localStorage.ff_directMessagingAnalytics ?? 'active',
  downloadAppPopup: localStorage.ff_downloadAppPopup ?? 'inactive',
  closeAccount: localStorage.ff_closeAccount ?? 'inactive',
  existingDomain: localStorage.ff_existingDomain ?? 'active',
  loggedOutAccess: localStorage.ff_loggedOutAccess ?? 'active',
  ignoreExploreAsGuestButton: localStorage.ff_ignoreExploreAsGuestButton ?? 'active',
  aboutPage: localStorage.ff_aboutPage ?? 'inactive',
  followInsteadOfRecognize: localStorage.ff_followInsteadOfRecognize ?? 'active',
  developerPages: localStorage.ff_developerPages ?? 'inactive',
  globalBottomNavbar: localStorage.ff_globalBottomNavbar ?? 'active',
  collectorProfileV2: localStorage.ff_collectorProfileV2 ?? 'active',
  globalTopNavbar: localStorage.ff_globalTopNavbar ?? 'active',
  buyModal: localStorage.ff_buyModal ?? 'active',
  shopPage: localStorage.ff_shopPage ?? 'active',
  cart: localStorage.ff_cart ?? 'active',
  googleLogin: localStorage.ff_googleLogin ?? 'active',
  arDeepLinkStaging: localStorage.ff_arDeepLinkStaging ?? 'inactive',
  loggedOutUserTraps: localStorage.ff_loggedOutUserTraps ?? 'active',
  universalSwipeHandlers: localStorage.ff_universalSwipeHandlers ?? 'active',
  editProfileMobile: localStorage.ff_editProfileMobile ?? 'active',
  editCollectorProfileMobile: localStorage.ff_editCollectorProfileMobile ?? 'active',
  home: localStorage.ff_home ?? 'active',
  arScore: localStorage.ff_arScore ?? 'active',
  preferredCurrency: localStorage.ff_preferredCurrency ?? 'active',
  allowLinksOnDM: localStorage.ff_allowLinksOnDM ?? 'active',
  kaliAutomatization: localStorage.ff_kaliAutomatization ?? 'active',
  artUniverse: localStorage.ff_artUniverse ?? 'active',
  aiAudioStatement: localStorage.ff_aiAudioStatement ?? 'active',
  autoOpenBuyModal: localStorage.ff_autoOpenBuyModal ?? 'active',
  myDocuments: localStorage.ff_myDocuments ?? 'active',
  applyToArtUniverse: localStorage.ff_applyToArtUniverse ?? 'active',
  myAnalytics: localStorage.ff_myAnalytics ?? 'active',
  myBioLinkUpgrade: localStorage.ff_myBioLinkUpgrade ?? 'active',
  milestonesV1: localStorage.ff_milestonesV1 ?? 'active',
  dashboardV2: localStorage.ff_dashboardV2 ?? 'active',
  loggedOutEcommerce: localStorage.ff_loggedOutEcommerce ?? 'active',
  openUpgradeToMyStudio: localStorage.ff_openUpgradeToMyStudio ?? 'active',
  redirectToMyWebsite: localStorage.ff_redirectToMyWebsite ?? 'active',
  myWebsite: localStorage.ff_myWebsite ?? 'active',
  printsEditionsMyDocuments: localStorage.ff_printsEditionsMyDocuments ?? 'active',
  promptUpgradeToMyStudio: localStorage.ff_promptUpgradeToMyStudio ?? 'active',
  generatedDocumentsEmails: localStorage.ff_generatedDocumentsEmails ?? 'active',
  dynamicCV: localStorage.ff_dynamicCV ?? 'active',
  upgradeFromEmergingToProfessional: localStorage.ff_upgradeFromEmergingToProfessional ?? 'active',
  mySubscribers: localStorage.ff_mySubscribers ?? 'active',
  dashboardFTUV2: localStorage.ff_dashboardFTUV2 ?? 'active',
  myWebsitePreviewPublic: localStorage.ff_myWebsitePreviewPublic ?? 'active',
  registerOnboardingV3: localStorage.ff_registerOnboardingV3 ?? 'active',
  applyDiscountCode: localStorage.ff_applyDiscountCode ?? 'active',
  tutorialCustomizeWebsiteFTU: localStorage.ff_tutorial_customize_websiteFTU ?? 'active',
  biolinkSectionReorder: localStorage.ff_biolinkSectionReorder ?? 'inactive',
  tutorialDashboardFTUNoTooltip: localStorage.ff_tutorial_dashboardFTUNoTooltip ?? 'active',
  analyzeMessageContent: localStorage.ff_tutorial_analyzeMessageContent ?? 'active',
  loggedOutEcommerce2: localStorage.ff_loggedOutEcommerce2 ?? 'inactive',
};

export const runtimeFlagUpdate: Partial<Record<FeatureFlags, boolean>> = {};
