import { Grid } from '@material-ui/core';
import React from 'react';
import { BasicImageType } from '../../Image/types';
import { ArtworkInformationType } from './ArtworkInformation';

export const ImageInfoLayoutMobile: React.FC<{
  children: [React.ReactElement<ArtworkInformationType>, React.ReactElement<BasicImageType>];
}> = ({ children }) => (
  <Grid container style={{ position: 'relative' }}>
    <Grid item xs={12} style={{ width: '100%' }}>
      {children[1]}
    </Grid>
    <Grid
      item
      style={{
        width: '100%',
      }}
    >
      {children[0]}
    </Grid>
  </Grid>
);
