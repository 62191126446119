import { useTranslation } from 'react-i18next';
import useApolloErrorHandler from 'common/features/ErrorHandling/hooks/useApolloErrorHandler';
import { useInsertFollowMutation } from 'common/schema/commonSchemaRemoteGraphqlQueries';
import { Enum_Flags_Type_Enum, Enum_Social_Graph_Followers_State_Enum } from 'common/schema/commonSchemaRemoteTypes';
import { AdmirationsModifiersObjectType } from 'store/slices/admirationsModifiersSlice';
import { useConfirmationModal } from 'common/contexts/ConfirmationModalProvider';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  NotificationModalState,
  permissionsStateActions,
  PermissionTypes,
} from 'stacks/PermissionsModal/store/permissionsStateSlice';
import { useLocation } from 'react-router';
import Auth from 'common/services/Auth';
import { ROUTES } from 'routes/_definition';
import { updateRecognitionsState } from '../utils/updateRecognitionsState';

export type handleFollowActionResponseType = {
  followId: number;
  resultData: AdmirationsModifiersObjectType;
};

const followResultInitialState = {
  id: 0,
  state: Enum_Social_Graph_Followers_State_Enum.Active,
  context_id: 0,
  target_context_id: 0,
};

type UseArtistAdmirationsActionsResultType = {
  handleShowConfirmationModal: (artistName: string) => Promise<boolean>;
  handleFollowAction: (
    contextId: number,
    targetContextId: number,
    newStateValue: Enum_Social_Graph_Followers_State_Enum,
    isRecognizing: boolean
  ) => Promise<handleFollowActionResponseType>;
};
type UseArtistAdmirationsActionsType = () => UseArtistAdmirationsActionsResultType;

export const useArtistAdmirationsActions: UseArtistAdmirationsActionsType = () => {
  const { t } = useTranslation();
  const [insertFollowMutation, { error: insertFollowErrors }] = useInsertFollowMutation();
  useApolloErrorHandler(insertFollowErrors);
  const { showConfirmationModal } = useConfirmationModal();
  const { onError } = useApolloErrorHandler();
  const dispatch = useDispatch();
  const [isRecognizingValue, setIsRecognizingValue] = useState<boolean>(false);
  const isLoggedIn = Auth.useIsLoggedIn();
  const isOnboarding = useLocation().pathname.startsWith(ROUTES.ONBOARDING);
  const [followState, setFollowState] = useState<Enum_Social_Graph_Followers_State_Enum>(
    Enum_Social_Graph_Followers_State_Enum.Active
  );
  const [followResult, setFollowResult] = useState<AdmirationsModifiersObjectType>(followResultInitialState);
  const mountedRef = useRef(true); // help clear the async queue of dispatchs in the useEffect and to prevent memory leaks

  useEffect(() => {
    if (followResult?.id !== followResultInitialState.id) {
      updateRecognitionsState(followState, followResult, isRecognizingValue, dispatch);
    }
    return () => {
      mountedRef.current = false;
    };
  }, [followResult, dispatch, followState, isRecognizingValue]);

  const handleFollowAction: UseArtistAdmirationsActionsResultType['handleFollowAction'] = useCallback(
    async (contextId, targetContextId, newStateValue, isRecognizing) => {
      const { data: mutationData } = await insertFollowMutation({
        variables: {
          context_id: contextId,
          target_context_id: targetContextId,
          state: newStateValue,
        },
        onError,
        fetchPolicy: 'network-only',
      });
      if (newStateValue === 'ACTIVE' && isLoggedIn && !isOnboarding) {
        dispatch(
          permissionsStateActions.UPDATE_NOTIFICATIONS_MODAL_STATE({
            permissionType: PermissionTypes.Notifications,
            modalType: Enum_Flags_Type_Enum.NotificationsRecognizeModal,
            status: NotificationModalState.Open,
            artistId: targetContextId,
          })
        );
      }

      const result: AdmirationsModifiersObjectType = {
        id: mutationData?.setFollowRelation || 0,
        context_id: contextId,
        target_context_id: targetContextId,
        state: newStateValue,
      };

      setFollowState(newStateValue);
      setIsRecognizingValue(isRecognizing);
      setFollowResult(result);

      return {
        followId: mutationData?.setFollowRelation || 0,
        resultData: result,
      };
    },
    [dispatch, isLoggedIn, isOnboarding, insertFollowMutation, onError]
  );

  const handleShowConfirmationModal: UseArtistAdmirationsActionsResultType['handleShowConfirmationModal'] = useCallback(
    (artistName) =>
      showConfirmationModal({
        title: t('dashboardStrings.admirations.tab.deleteModal.title'),
        message: t('dashboardStrings.admirations.tab.deleteModal.message', { 0: artistName }),
        cancelText: t('dashboardStrings.shareKaleido.showroomContainer.cancelButton'),
        okText: t('dashboardStrings.admirations.tab.deleteModal.confirmButton'),
      }),
    [t]
  );

  return {
    handleShowConfirmationModal,
    handleFollowAction,
  };
};
