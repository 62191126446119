export enum ROUTES {
  ROOT = '/',
  ACCOUNT_SETTINGS = '/account-settings',
  ADMIN = '/admin',
  ADMIN_ACCOUNTS = '/admin/accounts',
  ADMIN_DASHBOARD = '/admin/dashboard',
  ADMIN_DATA_DOG = '/admin/data-dog',
  ADMIN_STATS = '/admin/stats',
  ADMIN_TOOLS = '/admin-tools',
  AR = '/ar',
  ARTIST_HANDLE = '/:artistHandle',
  ARTIST_HANDLE_ABOUT = '/:artistHandle/about',
  ARTIST_HANDLE_ARTWORKS = '/:artistHandle/artworks',
  ARTIST_HANDLE_ARTWORKS_AVAILABILITY = '/:artistHandle/artworks/:availability',
  ARTIST_HANDLE_AVAILABLE_ART = '/:artistHandle/available-art',
  ARTIST_HANDLE_CARD = '/:artistHandle/card',
  ARTIST_HANDLE_CV = '/:artistHandle/cv',
  ARTIST_HANDLE_LINKS = '/:artistHandle/links',
  ARTIST_HANDLE_PROFILE = '/:artistHandle/profile',
  ARTIST_HANDLE_RECOGNITIONS = '/:artistHandle/recognitions',
  ARTIST_HANDLE_SERIES = '/:artistHandle/series',
  ARTIST_HANDLE_SERIES_SERIES = '/:artistHandle/series/:series',
  ARTIST_HANDLE_SHOWCASE = '/:artistHandle/showcase',
  ARTIST_HANDLE_SHOWCASE_ARTWORK_SLUG = '/:artistHandle/showcase/:artworkSlug',
  ARTIST_HANDLE_SHOWROOM = '/:artistHandle/showroom',
  ARTIST_HANDLE_SHOWROOM_TITLE = '/:artistHandle/showroom/:title',
  ARTIST_HANDLE_SHOWROOM_TITLE_SHOWCASE = '/:artistHandle/showroom/:title/showcase',
  ARTIST_HANDLE_SHOWROOM_TITLE_SHOWCASE_ARTWORK_SLUG = '/:artistHandle/showroom/:title/showcase/:artworkSlug',
  ARTIST_HANDLE_YEAR = '/:artistHandle/year',
  ARTIST_HANDLE_YEAR_YEAR = '/:artistHandle/year/:year',
  ART_PRIZE_ADMIN = '/art-prize-admin',
  ART_PRIZE_ADMIN_EDIT_ART_PRIZE = '/art-prize-admin/edit-art-prize',
  ART_PRIZE_ADMIN_EDIT_ART_PRIZE_ID = '/art-prize-admin/edit-art-prize/:id',
  ART_SHOW = '/art-show',
  ART_SHOW_SLUG = '/art-show/:slug',
  AR_KINETIC = '/ar/kinetic',
  AUTH = '/auth',
  AUTH_CONFIRM_NEW_EMAIL = '/auth/confirm-new-email',
  AUTH_FORGOT_PASSWORD = '/auth/forgot-password',
  AUTH_RESET_PASSWORD = '/auth/reset-password',
  BILLING_DASHBOARD = '/billing-dashboard',
  BIO_LINKS_MANAGER = '/bio-links-manager',
  CARD = '/card',
  CARD_HANDLE = '/card/:handle',
  CLAIM_CERTIFICATE = '/claim-certificate',
  CLAIM_CERTIFICATE_EMAIL_VALIDATION = '/claim-certificate/email-validation',
  CLAIM_CERTIFICATE_VERIFICATION = '/claim-certificate/verification',
  COLLECTIONS = '/collections',
  COLLECTIONS_SLUG = '/collections/:slug',
  COLLECTOR = '/collector',
  COLLECTOR_HANDLE = '/collector/:handle',
  COLLECTOR_HANDLE_FAVORITES = '/collector/:handle/favorites',
  COLLECTOR_HANDLE_RECOGNITIONS = '/collector/:handle/recognitions',
  COMPLETE_PROFILE = '/complete-profile',
  COMPLETE_PROFILE_REGISTER = '/complete-profile/register',
  COMPLETE_PROFILE_TYPE = '/complete-profile/type',
  DASHBOARD = '/dashboard',
  DASHBOARD_ADD_ARTWORK = '/dashboard/add-artwork',
  DASHBOARD_ANALYTICS = '/dashboard/analytics',
  DASHBOARD_APPLY_TO_MY_UNIVERSE = '/dashboard/apply-to-my-universe',
  DASHBOARD_ARTIST_PARTNERSHIPS = '/dashboard/artist-partnerships',
  DASHBOARD_BIO_LINK_MANAGER = '/dashboard/bio-link-manager',
  DASHBOARD_BIO_LINK_MANAGER_ANALYTICS = '/dashboard/bio-link-manager/analytics',
  DASHBOARD_BIO_LINK_MANAGER_MANAGE = '/dashboard/bio-link-manager/manage',
  DASHBOARD_CERTIFICATES = '/dashboard/certificates',
  DASHBOARD_CERTIFICATES_ARTWORK_ID = '/dashboard/certificates/:artworkId',
  DASHBOARD_CERTIFICATES_ARTWORK_ID_EDITION_ID = '/dashboard/certificates/:artworkId/:editionId',
  DASHBOARD_COMMUNITY = '/dashboard/community',
  DASHBOARD_COMMUNITY_FOLLOWERS = '/dashboard/community/followers',
  DASHBOARD_COMMUNITY_GALLERIES = '/dashboard/community/galleries',
  DASHBOARD_COMMUNITY_LINKS = '/dashboard/community/links',
  DASHBOARD_CONNECTIONS = '/dashboard/connections',
  DASHBOARD_CONNECTIONS_COLLECTORS = '/dashboard/connections/collectors',
  DASHBOARD_CONNECTIONS_IGNORED = '/dashboard/connections/ignored',
  DASHBOARD_CONNECTIONS_RECOGNITIONS = '/dashboard/connections/recognitions',
  DASHBOARD_CREATE_SHOWROOM = '/dashboard/create-showroom',
  DASHBOARD_DISCOVERY = '/dashboard/discovery',
  DASHBOARD_EDIT = '/dashboard/edit',
  DASHBOARD_EDIT_ARTWORK = '/dashboard/edit-artwork',
  DASHBOARD_EDIT_SHOWROOM = '/dashboard/edit-showroom',
  DASHBOARD_EDIT_SHOWROOM_ID = '/dashboard/edit-showroom/:id',
  DASHBOARD_FOLLOWERS = '/dashboard/followers',
  DASHBOARD_FOLLOWERS_ALL_FOLLOWERS = '/dashboard/followers/all-followers',
  DASHBOARD_FOLLOWERS_ARTISTS = '/dashboard/followers/artists',
  DASHBOARD_FOLLOWERS_COLLECTORS = '/dashboard/followers/collectors',
  DASHBOARD_GENERATE_AI_AUDIO = '/dashboard/generate-ai-audio',
  DASHBOARD_HELP = '/dashboard/help',
  DASHBOARD_INVITES = '/dashboard/invites',
  DASHBOARD_INVITE_AUDIENCE = '/dashboard/invite-audience',
  DASHBOARD_KALEIDO_CARD_MANAGER = '/dashboard/kaleido-card-manager',
  DASHBOARD_MANAGE_ART = '/dashboard/manage-art',
  DASHBOARD_MANAGE_TRANSFER = '/dashboard/manage-transfer',
  DASHBOARD_MANAGE_TRANSFER_ARTWORK_ID = '/dashboard/manage-transfer/:artworkId',
  DASHBOARD_MANAGE_TRANSFER_ARTWORK_ID_PRINT_ID = '/dashboard/manage-transfer/:artworkId/:printId',
  DASHBOARD_MY_DOCUMENTS = '/dashboard/my-documents',
  DASHBOARD_MY_SUBSCRIBERS = '/dashboard/my-subscribers',
  DASHBOARD_MY_WEBSITE = '/dashboard/my-website',
  DASHBOARD_PROFILE = '/dashboard/profile',
  DASHBOARD_PUBLISH = '/dashboard/publish',
  DASHBOARD_PUBLISH_ARTWORK_CONGRATS = '/dashboard/publish-artwork-congrats',
  DASHBOARD_PUBLISH_ARTWORK_CONGRATS_ID = '/dashboard/publish-artwork-congrats/:id',
  DASHBOARD_PUBLISH_CONGRATS = '/dashboard/publish/congrats',
  DASHBOARD_PUBLISH_CONGRATS_ARTWORK_ID = '/dashboard/publish/congrats/:artworkId',
  DASHBOARD_PUBLISH_CONGRATS_ARTWORK_ID_EDITION_ID = '/dashboard/publish/congrats/:artworkId/:editionId',
  DASHBOARD_RECOGNITIONS = '/dashboard/recognitions',
  DASHBOARD_RECOGNITIONS_IGNORED = '/dashboard/recognitions/ignored',
  DASHBOARD_SERIES = '/dashboard/series',
  DASHBOARD_SERIES_CREATE = '/dashboard/series/create',
  DASHBOARD_SERIES_EDIT = '/dashboard/series/edit',
  DASHBOARD_SERIES_EDIT_ID = '/dashboard/series/edit/:id',
  DASHBOARD_SETTINGS = '/dashboard/settings',
  DASHBOARD_SETTINGS_EMAILS = '/dashboard/settings/emails',
  DASHBOARD_SETTINGS_GENERAL = '/dashboard/settings/general',
  DASHBOARD_SHARE = '/dashboard/share',
  DASHBOARD_SHOWROOMS = '/dashboard/showrooms',
  DASHBOARD_SUPER_SITE = '/dashboard/super-site',
  DASHBOARD_TRANSFER_ARTWORK = '/dashboard/transfer-artwork',
  DASHBOARD_TRANSFER_ARTWORK_ARTWORK_ID = '/dashboard/transfer-artwork/:artworkId',
  DASHBOARD_TRANSFER_ARTWORK_ARTWORK_ID_PRINT_ID = '/dashboard/transfer-artwork/:artworkId/:printId',
  DASHBOARD_TRANSFER_PRINT = '/dashboard/transfer-print',
  DASHBOARD_TRANSFER_PRINT_ARTWORK_ID = '/dashboard/transfer-print/:artworkId',
  DASHBOARD_TRANSFER_PRINT_ARTWORK_ID_EDITION_BY_TYPE_ID = '/dashboard/transfer-print/:artworkId/:editionByTypeId',
  DASHBOARD_TRANSFER_PRINT_ARTWORK_ID_EDITION_BY_TYPE_ID_PRINT_NUMBER = '/dashboard/transfer-print/:artworkId/:editionByTypeId/:printNumber',
  DESTINATIONS = '/destinations',
  DESTINATIONS_LIST = '/destinations/list',
  DESTINATIONS_VIEW = '/destinations/view',
  DESTINATIONS_VIEW_ID = '/destinations/view/:id',
  DEVELOPER = '/developer',
  DEVELOPER_BUTTONS = '/developer/buttons',
  DEVELOPER_KALEIDO_COLOR_PALETTE = '/developer/kaleido-color-palette',
  DEVELOPER_LOGIN = '/developer/login',
  EDIT_PROFILE = '/edit-profile',
  EXPLORE = '/explore',
  FAVORITES = '/favorites',
  FAVORITES_ARTIST = '/favorites/:artist',
  FAVORITES_ARTIST_SHOWCASE = '/favorites/:artist/showcase',
  FAVORITES_ARTIST_SHOWCASE_ARTWORK = '/favorites/:artist/showcase/:artwork',
  FEATURED = '/featured',
  FOLLOWING = '/following',
  GALLERY = '/gallery',
  GALLERY_HANDLE = '/gallery/:handle',
  HANDLE = '/:handle',
  HANDLE_ABOUT = '/:handle/about',
  HANDLE_ARTWORKS = '/:handle/artworks',
  HANDLE_ARTWORKS_AVAILABILITY = '/:handle/artworks/:availability',
  HANDLE_AVAILABLE_ART = '/:handle/available-art',
  HANDLE_CARD = '/:handle/card',
  HANDLE_PRIZE = '/:handle/prize',
  HANDLE_PRIZE_PRIZE = '/:handle/prize/:prize',
  HANDLE_PROFILE = '/:handle/profile',
  HANDLE_RECOGNITIONS = '/:handle/recognitions',
  HANDLE_SERIES = '/:handle/series',
  HANDLE_SERIES_SERIES = '/:handle/series/:series',
  HANDLE_SHOWROOM = '/:handle/showroom',
  HANDLE_SHOWROOM_TITLE = '/:handle/showroom/:title',
  HANDLE_YEAR = '/:handle/year',
  HANDLE_YEAR_YEAR = '/:handle/year/:year',
  HOME = '/home',
  INQUIRE = '/inquire',
  INQUIRE_ARTWORK = '/inquire/artwork',
  INQUIRE_ARTWORK_ID = '/inquire/artwork/:id',
  JOIN = '/join',
  KALEIDO_CARD_MANAGER = '/kaleido-card-manager',
  LEARN_MORE = '/learn-more',
  LINKS = '/links',
  LINKS_HANDLE = '/links/:handle',
  LIVE = '/live',
  LIVE_SEARCH = '/live-search',
  MOBILE_ART_PUBLICATION = '/mobile-art-publication',
  MOBILE_ART_PUBLICATION_COMMERCIAL = '/mobile-art-publication/commercial',
  MOBILE_ART_PUBLICATION_COMMERCIAL_ID = '/mobile-art-publication/commercial/:id',
  MOBILE_ART_PUBLICATION_CONGRATS = '/mobile-art-publication/congrats',
  MOBILE_ART_PUBLICATION_CONGRATS_ID = '/mobile-art-publication/congrats/:id',
  MOBILE_ART_PUBLICATION_DETAILS = '/mobile-art-publication/details',
  MOBILE_ART_PUBLICATION_DETAILS_BLOCKCHAIN = '/mobile-art-publication/details/blockchain',
  MOBILE_ART_PUBLICATION_DETAILS_BLOCKCHAIN_ID = '/mobile-art-publication/details/blockchain/:id',
  MOBILE_ART_PUBLICATION_DETAILS_CREATION_DATE = '/mobile-art-publication/details/creation_date',
  MOBILE_ART_PUBLICATION_DETAILS_CREATION_DATE_ID = '/mobile-art-publication/details/creation_date/:id',
  MOBILE_ART_PUBLICATION_DETAILS_DIMENSIONS = '/mobile-art-publication/details/dimensions',
  MOBILE_ART_PUBLICATION_DETAILS_DIMENSIONS_ID = '/mobile-art-publication/details/dimensions/:id',
  MOBILE_ART_PUBLICATION_DETAILS_DISCIPLINE = '/mobile-art-publication/details/discipline',
  MOBILE_ART_PUBLICATION_DETAILS_DISCIPLINE_ID = '/mobile-art-publication/details/discipline/:id',
  MOBILE_ART_PUBLICATION_DETAILS_ID = '/mobile-art-publication/details/:id',
  MOBILE_ART_PUBLICATION_DETAILS_LOCATION = '/mobile-art-publication/details/location',
  MOBILE_ART_PUBLICATION_DETAILS_LOCATION_ID = '/mobile-art-publication/details/location/:id',
  MOBILE_ART_PUBLICATION_DETAILS_TITLE = '/mobile-art-publication/details/title',
  MOBILE_ART_PUBLICATION_DETAILS_TITLE_ID = '/mobile-art-publication/details/title/:id',
  MOBILE_ART_PUBLICATION_IMAGERY = '/mobile-art-publication/imagery',
  MOBILE_ART_PUBLICATION_IMAGERY_ID = '/mobile-art-publication/imagery/:id',
  MOBILE_ART_PUBLICATION_REVIEW = '/mobile-art-publication/review',
  MOBILE_ART_PUBLICATION_REVIEW_ID = '/mobile-art-publication/review/:id',
  MOBILE_ART_PUBLICATION_STORYTELLING = '/mobile-art-publication/storytelling',
  MOBILE_ART_PUBLICATION_STORYTELLING_ID = '/mobile-art-publication/storytelling/:id',
  MOBILE_MANAGE_ARTWORK = '/mobile-manage-artwork',
  MUSEUM = '/museum',
  MUSEUM_HANDLE = '/museum/:handle',
  MY_WEBSITE = '/my-website',
  MY_WEBSITE_ARTIST_HANDLE = '/my-website/:artistHandle',
  MY_WEBSITE_ARTIST_HANDLE_ABOUT = '/my-website/:artistHandle/about',
  MY_WEBSITE_ARTIST_HANDLE_ARTWORKS = '/my-website/:artistHandle/artworks',
  MY_WEBSITE_ARTIST_HANDLE_CART = '/my-website/:artistHandle/cart',
  MY_WEBSITE_ARTIST_HANDLE_CONTACT = '/my-website/:artistHandle/contact',
  MY_WEBSITE_ARTIST_HANDLE_SHOWCASE = '/my-website/:artistHandle/showcase',
  MY_WEBSITE_ARTIST_HANDLE_SHOWCASE_ARTWORK_SLUG = '/my-website/:artistHandle/showcase/:artworkSlug',
  MY_WEBSITE_ARTIST_HANDLE_UNSUBSCRIBE = '/my-website/:artistHandle/unsubscribe',
  MY_WEBSITE_CUSTOMIZE = '/my-website/customize',
  MY_WEBSITE_HANDLE = '/my-website/:handle',
  MY_WEBSITE_SETUP = '/my-website-setup',
  NEARBY = '/nearby',
  NEARBY_GALLERIES = '/nearby/galleries',
  ONBOARDING = '/onboarding',
  ONBOARDING_ARTIST = '/onboarding/artist',
  ONBOARDING_ARTIST_INTERESTS = '/onboarding/artist/interests',
  ONBOARDING_ARTIST_LOCATION = '/onboarding/artist/location',
  ONBOARDING_ARTIST_PRICE_RANGE = '/onboarding/artist/price-range',
  ONBOARDING_ARTIST_PROFILE = '/onboarding/artist/profile',
  ONBOARDING_ARTIST_STATEMENT = '/onboarding/artist/statement',
  ONBOARDING_ARTIST_WELCOME = '/onboarding/artist/welcome',
  ONBOARDING_ARTIST_WELCOME_VIDEO = '/onboarding/artist/welcome-video',
  ONBOARDING_GALLERY = '/onboarding/gallery',
  ONBOARDING_GALLERY_APPEARANCE = '/onboarding/gallery/appearance',
  ONBOARDING_GALLERY_PROFILE = '/onboarding/gallery/profile',
  ONBOARDING_GALLERY_SOCIAL = '/onboarding/gallery/social',
  ONBOARDING_GALLERY_STATEMENT = '/onboarding/gallery/statement',
  ONBOARDING_USER = '/onboarding/user',
  ONBOARDING_USER_BACKGROUND = '/onboarding/user/background',
  ONBOARDING_USER_INTERESTS = '/onboarding/user/interests',
  ONBOARDING_USER_LOCATION = '/onboarding/user/location',
  ONBOARDING_USER_RECOMMENDATIONS = '/onboarding/user/recommendations',
  ONBOARDING_USER_RECOMMENDATIONS_FEED = '/onboarding/user/recommendations/feed',
  ONBOARDING_USER_RECOMMENDATIONS_LOADING = '/onboarding/user/recommendations/loading',
  PASSWORD_REQUEST = '/password-request',
  PAYMENT = '/payment',
  PAYMENT_SUCCESSFUL = '/payment/successful',
  PRIVACY = '/privacy',
  PRIZES = '/prizes',
  PRIZES_SLUG = '/prizes/:slug',
  RECOGNITIONS = '/recognitions',
  RECOMMENDATIONS = '/recommendations',
  RECOMMENDATIONS_FEED = '/recommendations/feed',
  RECOMMENDATIONS_LOADING = '/recommendations/loading',
  REDIRECT_TO = '/redirect-to',
  REGISTER = '/register',
  REQUESTED_LOGIN = '/requested-login',
  SEARCH = '/search',
  SHOP = '/shop',
  SHOPPING_CART = '/shopping-cart',
  SKETCH_BOT = '/sketch-bot',
  TERMS = '/terms',
  WAITLIST = '/waitlist',
  WAITLIST_CLOSED_BETA = '/waitlist/closed-beta',
  WAITLIST_CLOSED_BETA_ACCOUNT_TYPE = '/waitlist/closed-beta/:accountType',
  WAITLIST_JOIN = '/waitlist/join',
  WAITLIST_JOIN_ACCOUNT_TYPE = '/waitlist/join/:accountType',
  WAITLIST_LANGUAGE = '/waitlist/language',
  WAITLIST_LANGUAGE_ACCOUNT_TYPE = '/waitlist/language/:accountType',
  _404 = '/404',
  _500 = '/500',
}

export type RouteParamsType =
  | [ROUTES.ARTIST_HANDLE, { artistHandle: string }]
  | [ROUTES.ARTIST_HANDLE_ABOUT, { artistHandle: string }]
  | [ROUTES.ARTIST_HANDLE_ARTWORKS, { artistHandle: string }]
  | [ROUTES.ARTIST_HANDLE_ARTWORKS_AVAILABILITY, { artistHandle: string; availability: string }]
  | [ROUTES.ARTIST_HANDLE_AVAILABLE_ART, { artistHandle: string }]
  | [ROUTES.ARTIST_HANDLE_CARD, { artistHandle: string }]
  | [ROUTES.ARTIST_HANDLE_CV, { artistHandle: string }]
  | [ROUTES.ARTIST_HANDLE_LINKS, { artistHandle: string }]
  | [ROUTES.ARTIST_HANDLE_PROFILE, { artistHandle: string }]
  | [ROUTES.ARTIST_HANDLE_RECOGNITIONS, { artistHandle: string }]
  | [ROUTES.ARTIST_HANDLE_SERIES, { artistHandle: string }]
  | [ROUTES.ARTIST_HANDLE_SERIES_SERIES, { artistHandle: string; series: string }]
  | [ROUTES.ARTIST_HANDLE_SHOWCASE, { artistHandle: string }]
  | [ROUTES.ARTIST_HANDLE_SHOWCASE_ARTWORK_SLUG, { artistHandle: string; artworkSlug: string }]
  | [ROUTES.ARTIST_HANDLE_SHOWROOM, { artistHandle: string }]
  | [ROUTES.ARTIST_HANDLE_SHOWROOM_TITLE, { artistHandle: string; title: string }]
  | [ROUTES.ARTIST_HANDLE_SHOWROOM_TITLE_SHOWCASE, { artistHandle: string; title: string }]
  | [
      ROUTES.ARTIST_HANDLE_SHOWROOM_TITLE_SHOWCASE_ARTWORK_SLUG,
      { artistHandle: string; title: string; artworkSlug: string }
    ]
  | [ROUTES.ARTIST_HANDLE_YEAR, { artistHandle: string }]
  | [ROUTES.ARTIST_HANDLE_YEAR_YEAR, { artistHandle: string; year: string }]
  | [ROUTES.ART_PRIZE_ADMIN_EDIT_ART_PRIZE_ID, { id: string }]
  | [ROUTES.ART_SHOW_SLUG, { slug: string }]
  | [ROUTES.CARD_HANDLE, { handle: string }]
  | [ROUTES.COLLECTIONS_SLUG, { slug: string }]
  | [ROUTES.COLLECTOR_HANDLE, { handle: string }]
  | [ROUTES.COLLECTOR_HANDLE_FAVORITES, { handle: string }]
  | [ROUTES.COLLECTOR_HANDLE_RECOGNITIONS, { handle: string }]
  | [ROUTES.DASHBOARD_CERTIFICATES_ARTWORK_ID, { artworkId: string }]
  | [ROUTES.DASHBOARD_CERTIFICATES_ARTWORK_ID_EDITION_ID, { artworkId: string; editionId: string }]
  | [ROUTES.DASHBOARD_EDIT_SHOWROOM_ID, { id: string }]
  | [ROUTES.DASHBOARD_MANAGE_TRANSFER_ARTWORK_ID, { artworkId: string }]
  | [ROUTES.DASHBOARD_MANAGE_TRANSFER_ARTWORK_ID_PRINT_ID, { artworkId: string; printId: string }]
  | [ROUTES.DASHBOARD_PUBLISH_ARTWORK_CONGRATS_ID, { id: string }]
  | [ROUTES.DASHBOARD_PUBLISH_CONGRATS_ARTWORK_ID, { artworkId: string }]
  | [ROUTES.DASHBOARD_PUBLISH_CONGRATS_ARTWORK_ID_EDITION_ID, { artworkId: string; editionId: string }]
  | [ROUTES.DASHBOARD_SERIES_EDIT_ID, { id: string }]
  | [ROUTES.DASHBOARD_TRANSFER_ARTWORK_ARTWORK_ID, { artworkId: string }]
  | [ROUTES.DASHBOARD_TRANSFER_ARTWORK_ARTWORK_ID_PRINT_ID, { artworkId: string; printId: string }]
  | [ROUTES.DASHBOARD_TRANSFER_PRINT_ARTWORK_ID, { artworkId: string }]
  | [ROUTES.DASHBOARD_TRANSFER_PRINT_ARTWORK_ID_EDITION_BY_TYPE_ID, { artworkId: string; editionByTypeId: string }]
  | [
      ROUTES.DASHBOARD_TRANSFER_PRINT_ARTWORK_ID_EDITION_BY_TYPE_ID_PRINT_NUMBER,
      { artworkId: string; editionByTypeId: string; printNumber: string }
    ]
  | [ROUTES.DESTINATIONS_VIEW_ID, { id: string }]
  | [ROUTES.FAVORITES_ARTIST, { artist: string }]
  | [ROUTES.FAVORITES_ARTIST_SHOWCASE, { artist: string }]
  | [ROUTES.FAVORITES_ARTIST_SHOWCASE_ARTWORK, { artist: string; artwork: string }]
  | [ROUTES.GALLERY_HANDLE, { handle: string }]
  | [ROUTES.HANDLE, { handle: string }]
  | [ROUTES.HANDLE_ABOUT, { handle: string }]
  | [ROUTES.HANDLE_ARTWORKS, { handle: string }]
  | [ROUTES.HANDLE_ARTWORKS_AVAILABILITY, { handle: string; availability: string }]
  | [ROUTES.HANDLE_AVAILABLE_ART, { handle: string }]
  | [ROUTES.HANDLE_CARD, { handle: string }]
  | [ROUTES.HANDLE_PRIZE, { handle: string }]
  | [ROUTES.HANDLE_PRIZE_PRIZE, { handle: string; prize: string }]
  | [ROUTES.HANDLE_PROFILE, { handle: string }]
  | [ROUTES.HANDLE_RECOGNITIONS, { handle: string }]
  | [ROUTES.HANDLE_SERIES, { handle: string }]
  | [ROUTES.HANDLE_SERIES_SERIES, { handle: string; series: string }]
  | [ROUTES.HANDLE_SHOWROOM, { handle: string }]
  | [ROUTES.HANDLE_SHOWROOM_TITLE, { handle: string; title: string }]
  | [ROUTES.HANDLE_YEAR, { handle: string }]
  | [ROUTES.HANDLE_YEAR_YEAR, { handle: string; year: string }]
  | [ROUTES.INQUIRE_ARTWORK_ID, { id: string }]
  | [ROUTES.LINKS_HANDLE, { handle: string }]
  | [ROUTES.MOBILE_ART_PUBLICATION_COMMERCIAL_ID, { id: string }]
  | [ROUTES.MOBILE_ART_PUBLICATION_CONGRATS_ID, { id: string }]
  | [ROUTES.MOBILE_ART_PUBLICATION_DETAILS_BLOCKCHAIN_ID, { id: string }]
  | [ROUTES.MOBILE_ART_PUBLICATION_DETAILS_CREATION_DATE_ID, { id: string }]
  | [ROUTES.MOBILE_ART_PUBLICATION_DETAILS_DIMENSIONS_ID, { id: string }]
  | [ROUTES.MOBILE_ART_PUBLICATION_DETAILS_DISCIPLINE_ID, { id: string }]
  | [ROUTES.MOBILE_ART_PUBLICATION_DETAILS_ID, { id: string }]
  | [ROUTES.MOBILE_ART_PUBLICATION_DETAILS_LOCATION_ID, { id: string }]
  | [ROUTES.MOBILE_ART_PUBLICATION_DETAILS_TITLE_ID, { id: string }]
  | [ROUTES.MOBILE_ART_PUBLICATION_IMAGERY_ID, { id: string }]
  | [ROUTES.MOBILE_ART_PUBLICATION_REVIEW_ID, { id: string }]
  | [ROUTES.MOBILE_ART_PUBLICATION_STORYTELLING_ID, { id: string }]
  | [ROUTES.MUSEUM_HANDLE, { handle: string }]
  | [ROUTES.MY_WEBSITE_ARTIST_HANDLE, { artistHandle: string }]
  | [ROUTES.MY_WEBSITE_ARTIST_HANDLE_ABOUT, { artistHandle: string }]
  | [ROUTES.MY_WEBSITE_ARTIST_HANDLE_ARTWORKS, { artistHandle: string }]
  | [ROUTES.MY_WEBSITE_ARTIST_HANDLE_CART, { artistHandle: string }]
  | [ROUTES.MY_WEBSITE_ARTIST_HANDLE_CONTACT, { artistHandle: string }]
  | [ROUTES.MY_WEBSITE_ARTIST_HANDLE_SHOWCASE, { artistHandle: string }]
  | [ROUTES.MY_WEBSITE_ARTIST_HANDLE_SHOWCASE_ARTWORK_SLUG, { artistHandle: string; artworkSlug: string }]
  | [ROUTES.MY_WEBSITE_ARTIST_HANDLE_UNSUBSCRIBE, { artistHandle: string }]
  | [ROUTES.MY_WEBSITE_HANDLE, { handle: string }]
  | [ROUTES.PRIZES_SLUG, { slug: string }]
  | [ROUTES.WAITLIST_CLOSED_BETA_ACCOUNT_TYPE, { accountType: string }]
  | [ROUTES.WAITLIST_JOIN_ACCOUNT_TYPE, { accountType: string }]
  | [ROUTES.WAITLIST_LANGUAGE_ACCOUNT_TYPE, { accountType: string }];

export enum CUSTOM_DOMAIN_ROUTES {
  ROOT = '/',
  ABOUT = '/about',
  ARTWORKS = '/artworks',
  ARTWORKS_AVAILABILITY = '/artworks/:availability',
  AVAILABLE_ART = '/available-art',
  CARD = '/card',
  CART = '/cart',
  CONTACT = '/contact',
  LINKS = '/links',
  NEWS_LETTER_DIALOG = '/news-letter-dialog',
  PROFILE = '/profile',
  RECOGNITIONS = '/recognitions',
  REDIRECT_TO = '/redirect-to',
  SERIES = '/series',
  SERIES_SERIES = '/series/:series',
  SHOWCASE = '/showcase',
  SHOWCASE_ARTWORK_SLUG = '/showcase/:artworkSlug',
  SHOWROOM = '/showroom',
  SHOWROOM_TITLE = '/showroom/:title',
  SHOWROOM_TITLE_SHOWCASE = '/showroom/:title/showcase',
  SHOWROOM_TITLE_SHOWCASE_ARTWORK_SLUG = '/showroom/:title/showcase/:artworkSlug',
  UNSUBSCRIBE = '/unsubscribe',
  YEAR = '/year',
  YEAR_YEAR = '/year/:year',
  _404 = '/404',
  _500 = '/500',
}

export type CustomDomainRouteParamsType =
  | [CUSTOM_DOMAIN_ROUTES.ARTWORKS_AVAILABILITY, { availability: string }]
  | [CUSTOM_DOMAIN_ROUTES.SERIES_SERIES, { series: string }]
  | [CUSTOM_DOMAIN_ROUTES.SHOWCASE_ARTWORK_SLUG, { artworkSlug: string }]
  | [CUSTOM_DOMAIN_ROUTES.SHOWROOM_TITLE, { title: string }]
  | [CUSTOM_DOMAIN_ROUTES.SHOWROOM_TITLE_SHOWCASE, { title: string }]
  | [CUSTOM_DOMAIN_ROUTES.SHOWROOM_TITLE_SHOWCASE_ARTWORK_SLUG, { title: string; artworkSlug: string }]
  | [CUSTOM_DOMAIN_ROUTES.YEAR_YEAR, { year: string }];
