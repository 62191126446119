export const toZIndexVar = <T extends string>(key: T): `--zindex-${T}` => `--zindex-${key}` as const;
const zIndexMap = {
  CARD_BADGE: 999,
  CREATE_ACCOUNT_CONTAINER: 999,
  PROVENANCE_PANEL: 999,
  ARTIST_INFO_LAYOUT: 1000,
  AUDIO_HEADER: 1000,
  MUI_MOBILE_STEPPER: 1000,
  ONBOARDING_LOGO: 1000,
  TOP_NAVBAR_BUTTONS: 1000,
  MUI_SPEED_DIAL: 1050,
  MUI_APP_BAR: 1100,
  MUI_DRAWER: 1200,
  POPOVER: 1298,
  DROPDOWN: 1299,
  WITH_ARTIST_HEADER: 1299,
  MUI_MODAL: 1300,
  NAVBAR: 1300,
  MOBILE_MENU: 1301,
  GLASSMORPHIC_NOTCH: 1301,
  GENERAL_MODAL: 1303,
  LOW_TOP_NAVBAR: 1303,
  LOW_BOTTOM_NAVBAR: 1303,
  DRAWER: 1304,
  MUI_SNACKBAR: 1400,
  LOGO_BUTTON: 1400,
  MUI_TOOLTIP: 1500,
  NAVBAR_SLIDE_BOX: 1500,
  MY_STUDIO_MODAL: 1501,
  SHOP_FILTERS_MODAL: 2300,
  SHOP_ARTWORK_SIZES_MODAL: 2301,
  PROVENANCE_CLOSE_BUTTON: 9999,
  ANNOUNCEMENT_MODAL: 10000,
  MESSAGE_SECTION: 10000,
  NOTIFICATION_PANEL: 10001,
  PROFILE_SIDEBAR: 10001,
  BLOCK_USER_MODAL: 10003,
  CHANGE_PROFILE_DIALOG: 10100,
  SHOPPING_CART_MOBILE: 14000,
  BUY_MODAL: 14000,
  UPGRADE_TO_MY_STUDIO_MODAL: 14000,
  PROMPT_UPGRADE_TO_MY_STUDIO_MODAL: 13999,
  GENERATE_NEW_CERTIFICATE_MODAL: 14000,
  NEW_DISCOUNT_CODES_MODAL: 14000,
  BUY_MODAL_TOOLTIP: 14001,
  FTU_MODAL: 99999,
  TOP_NAVBAR: 900001,
  BOTTOM_NAVBAR: 900001,
  CHAT_MESSAGING_CONTAINER: 900002,
  SWIPE_BACK_ARROW: 900003,
  ADMIN_WIDGET: 1300000,
  CONFIRMATION_MODAL: 1300001,
  KALI_AUTOMATIZATION_MODAL: 1300002,
  MODAL_OVERLAY: 1300003,
  SNACKBAR: 1300004,
  NOTIFICATION_MODAL_DIALOG: 9999999,
  PERMISSIONS_MODAL: 99999999,
  CONSOLE_DEBUG: 999999999,
} as const;

const zIndex = {
  values: zIndexMap,
};

export default zIndex;
