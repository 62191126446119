import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  Enum_My_Website_Artwork_Info_Enum,
  Enum_My_Website_Filters_Enum,
  Enum_My_Website_Layout_Enum,
  Enum_My_Website_Navigation_Enum,
  Enum_My_Website_Theme_Enum,
} from 'common/schema/commonSchemaRemoteTypes';

const NAVIGATION_ORDER = [
  Enum_My_Website_Navigation_Enum.SideNavigation,
  Enum_My_Website_Navigation_Enum.TopNavigation,
  Enum_My_Website_Navigation_Enum.FloatingNavigation,
];

export const isMyWebsiteNavigationEnum = (value: string): value is Enum_My_Website_Navigation_Enum =>
  Object.values(Enum_My_Website_Navigation_Enum).includes(value as Enum_My_Website_Navigation_Enum);

export const isMyWebsiteFilterEnum = (filter: string): filter is Enum_My_Website_Filters_Enum =>
  Object.values(Enum_My_Website_Filters_Enum).includes(filter as Enum_My_Website_Filters_Enum);

export const isMyWebsiteArtworkInfoEnum = (artworkInfo: string): artworkInfo is Enum_My_Website_Artwork_Info_Enum =>
  Object.values(Enum_My_Website_Artwork_Info_Enum).includes(artworkInfo as Enum_My_Website_Artwork_Info_Enum);

// TODO: Add 1 Column
export const ARTWORK_COLUMN_OPTIONS = [2, 3, 4];
export const PROFILE_ARTWORK_COLUMN_OPTIONS = [2, 3];
export const LAYOUT_OPTIONS = Object.values(Enum_My_Website_Layout_Enum);
export const ARTWORK_INFO_OPTIONS = Object.values(Enum_My_Website_Artwork_Info_Enum);
export const NAVIGATION_TYPES = Object.values(Enum_My_Website_Navigation_Enum).sort(
  (a, b) => NAVIGATION_ORDER.indexOf(a) - NAVIGATION_ORDER.indexOf(b)
);
export const FILTER_OPTIONS = Object.values(Enum_My_Website_Filters_Enum);

export type MyWebsiteFiltersType = {
  [key in Enum_My_Website_Filters_Enum]: boolean;
};

export type MyWebsiteSettingsState = {
  state?: 'IDLE' | 'LOADED';
  layout?: Enum_My_Website_Layout_Enum;
  columns?: number;
  artworkInfo?: Enum_My_Website_Artwork_Info_Enum;
  theme?: Enum_My_Website_Theme_Enum;
  overrideCustomDomain?: string;
  applyMyWebsiteSettings?: boolean;
  artworksOrderChanged?: number; // its a counter, can't be boolean, otherwise it will trigger the iframe to reload every time it sets to false again
  hidePreviewBanner?: boolean;
  artworksLength?: number;
  filters?: MyWebsiteFiltersType;
  navigation?: Enum_My_Website_Navigation_Enum;
  eCommerceEnabled?: boolean;
  publishedSettings?: string;
  isCustomize?: boolean;
  lastAutoUpdate?: number;
  lastUserUpdate?: number;
};

export const getDefaultMyWebsiteSettings = () => ({
  layout: Enum_My_Website_Layout_Enum.Grid,
  columns: 3,
  artworkInfo: Enum_My_Website_Artwork_Info_Enum.Overlay,
  theme: Enum_My_Website_Theme_Enum.Light,
  artworksOrderChanged: 0,
  hidePreviewBanner: true,
  artworksLength: 0,
  filters: {
    SHOW_SERIES: false,
    SHOW_YEARS: false,
  },
  navigation: Enum_My_Website_Navigation_Enum.SideNavigation,
  eCommerceEnabled: true,
  lastAutoUpdate: 0,
  lastUserUpdate: 0,
});

export const defaultMyWebsiteSettingsState = {
  state: 'IDLE',
  overrideCustomDomain: '',
  applyMyWebsiteSettings: false,
  publishedSettings: '',
  ...getDefaultMyWebsiteSettings(),
};

export const myWebsiteSettingsSlice = createSlice({
  name: 'myWebsiteSettings',
  initialState: defaultMyWebsiteSettingsState,
  reducers: {
    SET_MY_WEBSITE_SETTINGS: (state, action: PayloadAction<MyWebsiteSettingsState>) => {
      const { payload } = action;
      return {
        ...state,
        ...payload,
        lastUserUpdate: Date.now(),
      };
    },
    SET_OVERRIDE_CUSTOM_DOMAIN: (state, action: PayloadAction<string>) => {
      state.overrideCustomDomain = action.payload;
    },
    SET_APPLY_MY_WEBSITE_SETTINGS: (state, action: PayloadAction<boolean>) => {
      state.applyMyWebsiteSettings = action.payload;
    },
    SET_MY_WEBSITE_INITIAL_SETTINGS: (state, action: PayloadAction<MyWebsiteSettingsState>) => {
      const { payload } = action;
      return {
        ...state,
        ...payload,
        lastAutoUpdate: Date.now(),
      };
    },
  },
});
