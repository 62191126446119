import { AvailabilityEnum, AvailabilityFilterState } from './availability.types';

export const getDefaultAvailabilityFilter = (): AvailabilityFilterState => ({
  availability: {
    [AvailabilityEnum.ALL]: true,
    [AvailabilityEnum.AVAILABLE]: false,
    [AvailabilityEnum.UNAVAILABLE]: false,
    [AvailabilityEnum.COLLECTED]: false,
  },
});
export const defaultAvailabilityFilter = getDefaultAvailabilityFilter();
