import { logger } from '@old/services/logger';
import { CurrencyTypeEnum } from 'common/components/CurrencySelector/CurrencySelector.formik';
import { toMeasuringUnit, Unit, viewerUnitConversion } from 'common/contexts/UnitConversionProvider';
import {
  PrintEditionCommercialData,
  PrintPriceStatus,
  PrintPriceType,
  PrintsCommercialConfigValues,
  PrintSizePrices,
} from 'common/features/FormikConfigs/components/Prints';
import {
  GetArtworkCommercialDetailsQuery,
  GetArtworkDataForReviewQuery,
  GetEditionCommercialQuery,
  GetEditionReviewStepDataQuery,
  PrintDetailsFragment,
} from 'common/schema/commonSchemaRemoteOperationTypes';
import {
  Artwork_Secondary_Media,
  Edition_By_Size,
  Enum_Size_Enum,
  Maybe,
  Print,
} from 'common/schema/commonSchemaRemoteTypes';
import { TypeUtils } from 'common/utils/generalTypeUtils';
import { ArrayElement, NotNullish } from 'common/utils/types';
import { TFunction } from 'react-i18next';
import {
  getCollectorName,
  PrintProvenance,
} from 'stacks/ProfileArtShowcase/components/ProfileArtShowcaseLeftSide/components/ProfileArtShowcaseSidebar/components/PrintsProvenance/PrintsProvenanceList';
import { getCDNImageUrl } from 'utils/CDNImages';
import { formatDate, getArtworkStoryAudioLink, isSomeEnum } from 'utils/utilFunctions';
import { PRINTS_PER_PAGE } from '../../../utils/artworkPublishConstants';
import { CollaborationType } from '../../../utils/artworkPublishTypes';
import { MeasuringUnit } from '../../common/Review/components/ReviewArtworkDetails';
import { ArtworkDetails, ArtworkReviewData } from '../../common/Review/views/Review';
import { sortEditionTypes } from '../../PublishPrints/components/PrintsEdition/PrintsEditionTypes/hooks/useEditionTypes';
import { PrintSizeReviewProps } from '../../PublishPrints/components/PrintSizeReview/PrintSizeReview';
import { EditionReviewData } from '../../PublishPrints/components/PrintsReview/components/ReviewPrintsDetailsSection';
import { PrintsReviewProps } from '../../PublishPrints/components/PrintsReview/PrintsReview';
import { getEditionTypeName } from '../../PublishPrints/utils/publishPrintsUtils';

const getSecondaryMediaLink = (
  { id, file_metadata_id }: Pick<Artwork_Secondary_Media, 'id' | 'file_metadata_id'>,
  artworkId?: number
) =>
  getCDNImageUrl({
    target: 'Artwork',
    purpose: 'Secondary',
    targetId: Number(artworkId),
    fileId: file_metadata_id ?? 0,
    meta: {
      size: 'large',
      imageId: id,
    },
  });

export const mapArtworkReviewData = (
  artworkData: GetArtworkDataForReviewQuery['Artwork_by_pk'],
  artworkId: number,
  editionId?: number
): ArtworkReviewData | null => {
  if (!artworkData) {
    throw Error(`No data for artwork ${artworkId}`);
  }
  if (!artworkData.primary_image_metadata_id) {
    throw Error(`No primary image for artwork ${artworkData.id}`);
  }

  if (!artworkData.artwork_details) {
    throw Error(`No details for artwork ${artworkData.id}`);
  }

  if (!artworkData.artwork_story) {
    throw Error(`No story for artwork ${artworkData.id}`);
  }

  if (!editionId && !artworkData.artwork_details.creation_date_year) {
    throw Error(`No creation date year for artwork ${artworkData.id}`);
  }

  const rawDetails = artworkData.artwork_details;
  const rawStory = artworkData.artwork_story;
  const primaryImage = getCDNImageUrl({
    target: 'Artwork',
    purpose: 'Primary',
    targetId: Number(artworkData.id),
    fileId: artworkData.primary_image_metadata_id ?? 0,
    meta: {
      size: 'large',
    },
  });
  const secondaryImages: string[] = [];
  const processImages: string[] = [];

  // secondary images
  if (artworkData.artwork_secondary_media.length) {
    artworkData.artwork_secondary_media.forEach((s) => {
      const link = getSecondaryMediaLink(s, artworkData.id);
      if (link.length) {
        secondaryImages.push(link);
      }
    });
  }

  // process images
  if (rawStory?.artwork_story_media.length) {
    rawStory.artwork_story_media.forEach((s) => {
      const link = getCDNImageUrl({
        target: 'Artwork',
        purpose: 'Story',
        targetId: Number(artworkId),
        fileId: s.file_metadata_id ?? 0,
        meta: {
          size: 'large',
          imageId: s.id,
        },
      });
      if (link.length) {
        processImages.push(link);
      }
    });
  }

  const audioLink = getArtworkStoryAudioLink(rawStory);

  // artwork info
  const artworkDetails: ArtworkDetails = {
    ...rawDetails,
    title: rawDetails?.title || '',
    medium: rawDetails?.materials_and_medium || '',
    statement: rawStory?.statement || undefined,
    isDarkMode: rawDetails?.is_dark_mode || false,
    dimensions: {
      width: Math.round((rawDetails?.width ?? 0) * 100) / 100,
      height: Math.round((rawDetails?.height ?? 0) * 100) / 100,
      depth: Math.round((rawDetails?.depth ?? 0) * 100) / 100,
      unit: (rawDetails?.measuring_unit as MeasuringUnit) || 'inch',
    },
    audio: audioLink.length ? audioLink : undefined,
    createdOn: {
      day: rawDetails?.creation_date_day || undefined,
      month: rawDetails?.creation_date_month !== null ? rawDetails?.creation_date_month : undefined,
      year: rawDetails?.creation_date_year || new Date().getFullYear(),
    },
    discipline: rawDetails?.discipline || undefined,
  };

  // artwork video link
  if (rawStory?.artwork_story_video_links.length) {
    const videoData = rawStory.artwork_story_video_links[0];
    if (videoData.URL.length) {
      artworkDetails.video = videoData.URL;
    }
  }

  const result: ArtworkReviewData = {
    primaryImage,
    secondaryImages,
    processImages,
    artworkDetails,
    // vuforiaRating: artworkData.vuforia_rating ?? undefined,
  };

  if (artworkData.Artwork_prices.length) {
    const { price, currency, is_public: isPublic } = artworkData.Artwork_prices[0];
    const { is_available_sale: isAvailableForSale } = artworkData;
    if (isAvailableForSale) {
      if (!isSomeEnum(CurrencyTypeEnum)(currency)) {
        throw Error(`${currency} not in ${TypeUtils.Object.keys(CurrencyTypeEnum)}`);
      }
      result.commercial = {
        isPublic: !!isPublic,
        price: price ?? undefined,
        currency,
        isAvailableForSale,
      };
    }
  }

  // series raw data
  const collection = artworkData.artwork_to_collections.length
    ? artworkData.artwork_to_collections[0].Collection
    : null;

  if (collection) {
    // series video
    const seriesVideo = collection.collection_video_links.length ? collection.collection_video_links[0] : undefined;

    // series
    result.series = {
      title: collection.name || '',
      statement: collection.description || undefined,
      audio: collection.collection_audio_download_link || undefined,
      video: seriesVideo?.URL,
    };
  }

  if (artworkData?.artwork_to_tags?.length) {
    result.artworkTags = artworkData.artwork_to_tags.map((t) => ({ name: t.tag.name, type: t.type }));
  }

  return result;
};
type formatDimensionsParamsType = {
  values: number[];
  unit: string;
  transform?: (unit: Unit, value: number[]) => { unit: Unit; value: number[] };
};
export const formatDimensions = (params: formatDimensionsParamsType): string => {
  const transformFunction = params.transform ?? viewerUnitConversion.convert;
  const parsedDimensionsValues = transformFunction(toMeasuringUnit(params.unit), params.values?.filter(Number) ?? []);
  const concatenatedDimensions = parsedDimensionsValues.value.map((d) => d.toString()).join(' x ');

  if (!concatenatedDimensions.length) {
    return '';
  }

  return `${concatenatedDimensions} ${parsedDimensionsValues.unit}`;
};

export const mapPrintDetailsFragment = (
  { id, editionByTypeId, number, price, status, acceptedTransactions }: PrintDetailsFragment,
  t: TFunction
): PrintPriceStatus => ({
  id,
  price: price ?? undefined,
  number,
  status,
  editionByTypeId,
  collector: getCollectorName(acceptedTransactions, t),
});

const mapPrintSizeReviewProps = (
  editionBySize: ArrayElement<NonNullable<GetEditionReviewStepDataQuery['Edition_by_pk']>['Edition_By_Sizes']>,
  currency: CurrencyTypeEnum,
  price: number,
  t: TFunction
): Omit<PrintSizeReviewProps, 'printPriceType'> => {
  if (
    !editionBySize.technique ||
    !editionBySize.print_date_year ||
    !editionBySize.width ||
    !editionBySize.height ||
    !editionBySize.measuring_unit
  ) {
    throw new Error('Invalid data');
  }

  const editions = editionBySize.Edition_By_Types.sort((a, b) =>
    sortEditionTypes(a.Edition_Type.value, b.Edition_Type.value)
  ).map<ArrayElement<PrintSizeReviewProps['editions']>>(({ id, Edition_Type: { value }, Prints, print_amount }) => {
    if (!print_amount) {
      throw Error(`No print_amount for edition_by_type ${id}`);
    }
    const printsCount = Math.min(Prints.length, PRINTS_PER_PAGE);
    const prints: PrintPriceStatus[] = [];
    for (let i = 1; i <= printsCount; i++) {
      const existingPrint = Prints.find((p) => p.number === i);
      if (existingPrint) {
        prints.push(mapPrintDetailsFragment(existingPrint, t));
      } else {
        prints.push({
          id: -i,
          price: price ?? 0,
          editionByTypeId: id,
          number: i,
        });
      }
    }
    return {
      id,
      printsCount: print_amount,
      name: getEditionTypeName(value, t),
      prints,
    };
  });

  const { print_date_year, print_date_month, print_date_day } = editionBySize;

  return {
    technique: t(`publish.prints.technique.${editionBySize.technique}`),
    material: editionBySize.materials ?? undefined,
    size: editionBySize.print_size ?? undefined,
    date: formatDate(print_date_year, print_date_month, print_date_day),
    dimensions: formatDimensions({
      values: [editionBySize.width, editionBySize.height],
      unit: editionBySize.measuring_unit,
    }),
    currency,
    editions,
    fixedPrice: editionBySize.price ?? undefined,
    isPricePublic: editionBySize.isPricePublic ?? undefined,
  };
};

export const getEditionPriceType = (
  editionCurrency?: Maybe<string>,
  editionSizes?: Maybe<
    Array<
      Pick<Edition_By_Size, 'price' | 'is_price_public'> & {
        Edition_By_Types: Array<{ Prints: Array<Pick<Print, 'price'>> }>;
      }
    >
  >
): PrintPriceType | undefined => {
  // TODO: refactor this after adding new price type column on edition table
  // Editions with custom price will have all sizes is_price_public true
  const hasHiddenNoPrice = editionSizes?.some((s) => s.price || s.is_price_public === false);
  let printPriceType = hasHiddenNoPrice && editionCurrency ? PrintPriceType.FIXED : undefined;
  if (!printPriceType) {
    const hasPrintPrices = editionSizes?.reduce(
      (acc, curr) => acc || curr.Edition_By_Types.some((ebt) => ebt.Prints.length > 0),
      false
    );
    if (hasPrintPrices) {
      printPriceType = PrintPriceType.CUSTOM;
    }
  }
  return printPriceType;
};

export const mapEditionReviewData = (
  edition: NotNullish<GetEditionReviewStepDataQuery['Edition_by_pk']>,
  defaultCurrency: CurrencyTypeEnum,
  t: TFunction
): Pick<PrintsReviewProps, 'secondaryImages'> & PrintsReviewProps['editionData'] => {
  const {
    id,
    Artwork_secondary_media,
    Edition_By_Sizes,
    price,
    currency: editionCurrency,
    is_available_sale,
    is_price_public,
  } = edition;

  let currency = defaultCurrency;
  if (!editionCurrency) {
    logger.warn(`No currency type for edition ${id}`);
  } else if (isSomeEnum(CurrencyTypeEnum)(editionCurrency)) {
    currency = editionCurrency;
  }

  const secondaryImages: string[] = [];
  Artwork_secondary_media.forEach((s) => {
    const link = getSecondaryMediaLink(s, id);
    if (link.length) {
      secondaryImages.push(link);
    }
  });

  const printPriceType = getEditionPriceType(currency, Edition_By_Sizes);

  const printSizesData = Edition_By_Sizes.reduce<EditionReviewData['printSizesData']>((data, sizeData) => {
    data[sizeData.print_size] = {
      ...mapPrintSizeReviewProps(sizeData, currency, price ?? 0, t),
      printPriceType,
    };
    return data;
  }, {});

  return {
    secondaryImages,
    printSizesData,
    price: price ?? undefined,
    currency,
    isAvailableForSale: !!is_available_sale,
    isPublic: !!is_price_public,
    printPriceType,
  };
};

export const mapEditionPrices = (
  data: GetEditionCommercialQuery,
  t: TFunction,
  defaultCurrency: CurrencyTypeEnum
): PrintsCommercialConfigValues => {
  const printPriceType = getEditionPriceType(data.Edition_by_pk?.currency, data.Edition_by_pk?.Edition_By_Sizes);
  const editionCommercialValues: PrintsCommercialConfigValues = { printPriceType };

  if (!data.Edition_by_pk) {
    return editionCommercialValues;
  }

  editionCommercialValues.printSizes = data.Edition_by_pk.Edition_By_Sizes.reduce<
    PrintsCommercialConfigValues['printSizes']
  >((values, size) => {
    const sizeValues = size.Edition_By_Types.reduce<PrintSizePrices>(
      (types, type) => {
        types.printDimensions = {
          width: size.width ?? undefined,
          height: size.height ?? undefined,
        };

        const existingPrintPrices: PrintEditionCommercialData = {
          ...types.printPrices[type.Edition_Type.value],
          id: type.id,
          name: type.Edition_Type.value,
          status: type.status,
          printAmount: type.print_amount,
        };

        existingPrintPrices.prices = type.Prints.map((p) => mapPrintDetailsFragment(p, t));
        types.printPrices[type.Edition_Type.value] = existingPrintPrices;
        return types;
      },
      { printPrices: {}, printDimensions: {} }
    );

    const currency = size.currency ?? data.Edition_by_pk?.currency;
    (values ?? {})[size.print_size] = {
      ...sizeValues,
      price: size.price ?? undefined,
      currency: isSomeEnum(CurrencyTypeEnum)(currency) ? currency : defaultCurrency,
      showPrice: size.is_price_public,
    };

    return values;
  }, {});
  return editionCommercialValues;
};

export const mapArtworkCollaborations = (
  data?: Pick<GetArtworkCommercialDetailsQuery, 'collaborations' | 'invitations'>
): CollaborationType[] => {
  if (!data) {
    return [];
  }
  return [
    ...(data.collaborations
      ?.filter((collab) => collab.Context?.collaboration_by_gallery_id[0].id)
      .map((collab) => ({
        email: collab.email || '',
        name: collab.title || '',
        collabId: collab.Context?.collaboration_by_gallery_id[0].id,
      })) ?? []),
    ...(data.invitations
      ?.filter((invite) => invite.profile_invitation.id)
      .map((invite) => ({
        email: invite.profile_invitation.invitee_email || '',
        name: invite.invitation_gallery_name || '',
        inviteId: invite.profile_invitation.id,
      })) ?? []),
  ];
};

type PrintListItem = Pick<PrintProvenance, 'number' | 'editionByTypeId'> & { size?: Enum_Size_Enum };

export const generatePrintsList = <T extends PrintListItem>(
  page = 1,
  total: number,
  existingPrices: T[] = [],
  editionByTypeId: PrintListItem['editionByTypeId'],
  size: PrintListItem['size']
): T[] => {
  const prints: T[] = [];
  const totalPages = Math.ceil(total / PRINTS_PER_PAGE);
  let pagePrintsCount = Math.min(total, PRINTS_PER_PAGE);
  const lastPageItemsCount = total % PRINTS_PER_PAGE;
  if (page === totalPages && lastPageItemsCount > 0) {
    pagePrintsCount = lastPageItemsCount;
  }

  for (let i = 1; i <= pagePrintsCount; i++) {
    const printNumber = (page - 1) * PRINTS_PER_PAGE + i;
    const existing = existingPrices?.find(({ number }) => printNumber === number);
    prints.push({
      ...existing,
      editionByTypeId,
      number: printNumber,
      size,
    } as T); // TODO: should be safe, but find a way to avoid casting
  }

  return prints;
};

// TODO: use generic generate prints list function
export const generatePrintPriceStatusList = (
  page = 1,
  total: number,
  existingPrices: PrintPriceStatus[] = [],
  editionByTypeId: number
) => {
  const printsPrices: PrintPriceStatus[] = [];
  const totalPages = Math.ceil(total / PRINTS_PER_PAGE);
  let pagePrintsCount = Math.min(total, PRINTS_PER_PAGE);
  const lastPageItemsCount = total % PRINTS_PER_PAGE;
  if (page === totalPages && lastPageItemsCount > 0) {
    pagePrintsCount = lastPageItemsCount;
  }

  for (let i = 1; i <= pagePrintsCount; i++) {
    const printNumber = (page - 1) * PRINTS_PER_PAGE + i;
    const existing = existingPrices?.find(({ number }) => printNumber === number);
    printsPrices.push({
      editionByTypeId,
      number: printNumber,
      ...existing,
    });
  }

  return printsPrices;
};
