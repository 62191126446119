import { Grid } from '@material-ui/core';
import { ColumnText } from 'common/components/TypographyText/ColumnText';
import { TypographyTextType } from 'common/components/TypographyText/SkeletonText';
import React from 'react';

const defaultTitleTextProps: TypographyTextType = {
  variant: 'h4',
  style: {
    color: '#3A3C41;',
    fontWeight: 400,
    maxWidth: '100%',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontSize: '14px',
    lineHeight: '18px',
  },
};

export type ArtworkInformationProps = {
  ctaStyle?: React.CSSProperties;
  title?: string;
  details?: string;
  infoCTA?: React.ReactNode;
  overrideArtworkInfoTextProps?: {
    title?: TypographyTextType;
    details?: TypographyTextType;
  };
  prefix?: {
    title?: React.ReactNode;
  };
};
export type ArtworkInformationType = React.FC<ArtworkInformationProps>;
export const ArtworkInformationMobile: ArtworkInformationType = ({ ctaStyle, infoCTA, title, prefix }) => (
  <Grid
    container
    alignItems="center"
    style={{
      margin: '2px 0px 8px 0px',
      padding: '0px 2px',
      position: 'relative',
      flexWrap: 'nowrap',
      maxWidth: 'calc(100% + 8px)',
    }}
  >
    <ColumnText className="details">
      {[
        {
          text: title,
          ...defaultTitleTextProps,
          prefixText: prefix?.title,
        },
      ]}
    </ColumnText>

    {infoCTA && <div style={ctaStyle}>{infoCTA}</div>}
  </Grid>
);
