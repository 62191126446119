import { LazyQueryResult, useLazyQuery } from '@apollo/client';
import { UnitConversionContext } from 'common/contexts/UnitConversionProvider';
import useApolloErrorHandler from 'common/features/ErrorHandling/hooks/useApolloErrorHandler';
import { FeatureFlagsState } from 'common/features/FeatureFlag/config/features';
import { featureFlagActions } from 'common/features/FeatureFlag/store/featureFlagSlice';
import { GET_PROFILE_PHOTO } from 'common/graphql/authQueries';
import useAppSelector from 'common/hooks/useAppSelector';
import {
  useGetDefaultContextQuery,
  useGetUserProfilesLazyQuery,
  useGetUserProfileTypeQuery,
  useGetUserRolesQuery,
} from 'common/schema/commonSchemaRemoteGraphqlQueries';
import { GetUserProfilesQuery } from 'common/schema/commonSchemaRemoteOperationTypes';
import {
  Context,
  Enum_Context_Type_Enum,
  Enum_Profile_Status_Enum,
  Exact,
} from 'common/schema/commonSchemaRemoteTypes';
import { languageLocalesList } from 'common/utils/languageUtils';
import i18n from 'i18n';
import React, { useCallback, useContext, useEffect, useImperativeHandle, useRef } from 'react';
import { useDispatch } from 'react-redux';
import useSaveDefaultContext from 'stacks/OnboardingV2/hooks/useSaveDefaultContext';
import { accountActions } from 'store/slices/account';
import { getCDNImageUrl } from 'utils/CDNImages';
import { contextToUserProfile } from 'utils/utilFunctions';
import { IS_CUSTOM_DOMAINS_APP } from 'utils/constants';
import Auth from 'common/services/Auth';
import { updateAuthState } from 'common/services/Auth/handlers/AuthHandler';
import useFetchUserSubscriptions from 'common/hooks/useFetchUserSubscriptions';
import { useFetchUserDocumentsCount } from 'common/hooks/useFetchUserDocumentsCount';
import { useFetchUserScamInfractions } from 'common/hooks/useFetchUserScamInfractions';

type ProfileProviderType = {
  forceUpdateProfiles: () => void;
  forceRefreshProfilePhoto: (id: number | null) => void;
};

export const ProfileProviderRef = React.createRef<ProfileProviderType>();

const ProfileProvider = ({ children }: any) => {
  if (IS_CUSTOM_DOMAINS_APP) {
    return children;
  }
  const isAuthenticated = Auth.useIsAuthenticated();
  const appAccessId = Auth.useFirebaseUserId();
  const [getAllProfiles, { error: getAllProfilesError }] = useGetUserProfilesLazyQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });
  const profiles = useAppSelector((store) => store.account.profiles);

  const fetchingContext = useRef(true);
  const { data: defaultContextRes } = useGetDefaultContextQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    skip: !appAccessId,
  });
  const { data: rolesData } = useGetUserRolesQuery({
    variables: { contextId: appAccessId || 0 },
    fetchPolicy: 'network-only',
    skip: !appAccessId,
  });

  const profileId = useAppSelector((store) => store.account.selectedProfileId);

  const dispatch = useDispatch();
  const saveDefaultContext = useSaveDefaultContext();
  const { setUserSystem } = useContext(UnitConversionContext);
  const [getProfilePhoto, { data: profilePhotoData }] = useLazyQuery(GET_PROFILE_PHOTO, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'standby',
  });
  const { data: typeData, loading: typeLoad } = useGetUserProfileTypeQuery({
    variables: { profileId },
    fetchPolicy: 'network-only',
    skip: !profileId,
  });

  const { fetchUserSubscriptions } = useFetchUserSubscriptions();
  useEffect(() => {
    fetchUserSubscriptions();
  }, [fetchUserSubscriptions]);

  useFetchUserDocumentsCount();

  useFetchUserScamInfractions();

  useApolloErrorHandler(getAllProfilesError);
  const handleGetProfilesResponse = useCallback(
    (result: LazyQueryResult<GetUserProfilesQuery, Exact<{ id: number }>>) => {
      if (!appAccessId) {
        return;
      }
      dispatch(accountActions.SET_PROFILE_PROVIDER_FINISHED());
      const { data, loading } = result;
      if (!data || loading) {
        dispatch(accountActions.SET_PROFILES([]));
        dispatch(accountActions.SET_INCOMPLETE_PROFILES([]));
      }
      const incompleteProfiles = data?.Flat_context_access?.filter(
        (fca) => fca.granter && fca.granter.profile?.status === Enum_Profile_Status_Enum.Incomplete
      ).map((fca) => contextToUserProfile(fca.granter as Context));
      const activeProfiles = data?.Flat_context_access?.filter(
        (fca) => fca.granter && fca.granter.profile?.status !== Enum_Profile_Status_Enum.Incomplete
      ).map((fca) => contextToUserProfile(fca.granter as Context));

      if (!defaultContextRes?.default[0].contextId) {
        if (activeProfiles && activeProfiles.length) {
          dispatch(accountActions.SET_SELECTED_PROFILE(activeProfiles[0]?.contextId));
          saveDefaultContext(appAccessId, activeProfiles[0]?.contextId ?? 0);
        } else if (incompleteProfiles && incompleteProfiles.length) {
          dispatch(accountActions.SET_SELECTED_PROFILE(incompleteProfiles[0]?.contextId));
          saveDefaultContext(appAccessId, incompleteProfiles[0]?.contextId ?? 0);
        }
      }
      setUserSystem(activeProfiles?.[0]?.locations?.[0]?.country || 'RO');
      dispatch(accountActions.SET_PROFILES(activeProfiles));
      dispatch(accountActions.SET_INCOMPLETE_PROFILES(incompleteProfiles));

      if (data?.Context_by_pk) {
        dispatch(accountActions.SET_PROFILE_STATUS(data?.Context_by_pk?.state));

        // used for A/B testing
        const features = data?.Context_by_pk?.features || [];
        // transform from array to object for easier access
        const flagStateOverride: Partial<FeatureFlagsState> = features.reduce(
          (obj, item) => ({ ...obj, [item.feature_flag]: item.flag_status }),
          {}
        );
        if (Object.keys(flagStateOverride).length !== 0) {
          dispatch(featureFlagActions.setFlags(flagStateOverride));
        }

        const contextLanguage = data?.Context_by_pk?.auth_context_accesses?.[0].auth?.preferred_language || null;
        if (contextLanguage) {
          dispatch(accountActions.SET_PREFERRED_LANGUAGE(contextLanguage));
          i18n.changeLanguage(languageLocalesList[contextLanguage]);
        }
      }
    },
    [defaultContextRes, appAccessId]
  );

  useEffect(() => {
    if (profileId) {
      getProfilePhoto({
        variables: {
          id: profileId,
        },
      });
    }
  }, [profileId, getProfilePhoto]);

  useEffect(() => {
    if (profilePhotoData?.Profile_by_pk?.id) {
      dispatch(
        accountActions.SET_PROFILE_PHOTO_LINK(
          getCDNImageUrl({
            target: 'Profile',
            purpose: 'Avatar',
            targetId: profilePhotoData.Profile_by_pk.id,
            fileId: profilePhotoData.Profile_by_pk.avatar,
            meta: {
              size: 'large',
            },
          })
        )
      );
    }
  }, [profilePhotoData]);

  useEffect(() => {
    // This should run only after Firebase user is authenticated
    if (!isAuthenticated) {
      return;
    }
    if (appAccessId && !fetchingContext.current) {
      getAllProfiles({ variables: { id: Number(appAccessId) } }).then(handleGetProfilesResponse);
    } else {
      dispatch(accountActions.SET_PROFILES([]));
    }
  }, [isAuthenticated, appAccessId, fetchingContext.current]);

  useEffect(() => {
    if (defaultContextRes) {
      fetchingContext.current = false;
      dispatch(accountActions.SET_SELECTED_PROFILE(defaultContextRes?.default[0].contextId ?? 0));
    }
  }, [defaultContextRes]);

  useEffect(() => {
    if (!typeLoad && typeData?.Profile_by_pk?.type) {
      dispatch(accountActions.SET_SUB_PROFILE_TYPE(typeData?.Profile_by_pk?.type));
    }
  }, [typeData, typeLoad]);

  useEffect(() => {
    if (!rolesData) {
      return;
    }

    dispatch(
      accountActions.SET_USER_ROLES(
        Array.from(
          new Set(
            rolesData.Flat_context_access.map((context) => context.granter?.type).filter(
              (role): role is Enum_Context_Type_Enum => !!role
            )
          )
        )
      )
    );
  }, [rolesData]);

  useEffect(() => {
    // This is used to avoid calling this in all places where we update the profiles
    updateAuthState();
  }, [profiles]);

  useImperativeHandle(ProfileProviderRef, () => ({
    forceUpdateProfiles: () =>
      getAllProfiles({ variables: { id: Number(appAccessId) } }).then(handleGetProfilesResponse),
    forceRefreshProfilePhoto: (id = null) =>
      getProfilePhoto({
        variables: {
          id,
        },
      }),
  }));
  // Statsig.updateUser({ userID: userId });

  return children;
};

export default ProfileProvider;
